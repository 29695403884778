import {
  Button,
  Input,
  Popover,
  PopoverBody,
  Col,
  Row,
  PopoverHeader,
} from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import "../../assets/styles/CreateBill.css";
import "../../assets/styles/Invoice.css";
import { isEmpty } from "underscore";
import { Tooltip } from "react-tooltip";
import { useLoginStore } from "hooks/Login";
import AdvanceFilterModal from "./AdvanceFilterModal";
import { Dialog } from "primereact/dialog";
import InvoiceBillStep from "./InvoiceBillStep";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";
import { FaRegTrashCan, FaTrash, FaUpload } from "react-icons/fa6";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomButton from "components/Button/CustomButton";
import PlusMinusButton from "components/Button/PlusMinusButton";
import Common from "helpers/Common";
import moment from "moment";
import { RiAddFill, RiArrowRightLine } from "react-icons/ri";
import { MdContactSupport } from "react-icons/md";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import "../../assets/styles/CreateBillDraftButton.css";
import allDrafts from "assets/img/icons/createBill/allDrafts.png";
import closeIcon from "../../assets/img/icons/createBill/closeIcon.png";
import lookup from "../../assets/img/icons/createBill/lookup.png";
import deleteIcon from "../../assets/img/icons/createBill/delete.png";
import PageLoader from "components/Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { URL_INVENTORY_PRODUCTS_ADD } from "helpers/Paths";
import { Card, CardBody } from "react-bootstrap";
import { LuPackagePlus } from "react-icons/lu";

const BillSummary = (props) => {
  const {
    selectedCustomer,
    setSelectedCustomer,
    customerData,
    setCustomerData,
    rows,
    setRows,
    manualDiscount,
    setManualDiscount,
    setShowInRupees,
    showInRupees,
    igstPercentage,
    setIgstPercentage,
    paymentMethod,
    setPaymentMethod,
    paymentDone,
    setPaymentDone,
    billId,
    setBillId,
    subTotal,
    setSubTotal,
    totalAmount,
    setTotalAmount,
    totalBeforeTax,
    setTotalBeforeTax,
    createDraft,
    setRecentBillId,
    selectedCustomerID,
    selectedRecentProduct,
    isDraftCreated,
  } = props;

  const [isDropdownFocused, setIsDropdownFocused] = useState(false);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [visible, setVisible] = useState(false);
  const [SelectedRecentProdIndex, setSelectedRecentProdIndex] = useState(0);
  const [focusedButton, setFocusedButton] = useState(null); // Track which button is focused
  const [focusIndex, setFocusIndex] = useState(null);
  const [popoverDraftOpen, setPopoverDraftOpen] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [expiryDays, setExpiryDays] = useState(null);
  const [isFixedSellPriceDiscount, setIsFixedSellPriceDiscount] =
    useState(false);
  const currency = new CreateBillRepository().getCurrencyAbbriviation();

  const [{}, { getProductSearchList }, { setSuggestedProduct, saveInvoice }] =
    useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loader, setLoader] = useState(false);
  const debounceTimer = useRef(null);
  const inputRefs = useRef([]);
  const rowsRef = useRef(rows);
  const selectedCustomerRef = useRef(selectedCustomer);
  const customerDataRef = useRef(customerData);
  const totalAmountRef = useRef(totalAmount);
  const autoCompleteRef = useRef(null);
  const paymentModeRef = useRef(null);
  const manualDiscountRef = useRef(null);
  const igstInputRef = useRef(null);
  const saveBtnRef = useRef(null);
  const draftBtnRef = useRef(null);
  const sgstRef = useRef(null);
  const cgstRef = useRef(null);
  const igstRef = useRef(null);
  const totalBeforeTaxRef = useRef(null);
  const subTotalRef = useRef(null);
  const manualDiscountTotalRef = useRef(null);
  const manualDiscountAmountRef = useRef(0);
  const igstAmountRef = useRef(0);
  const discountTypeRef = useRef(null);
  const [isOn, setIsOn] = useState(() => {
    // Check if 'scan' is stored in localStorage and return true or false accordingly
    const savedState = localStorage.getItem("scan");
    return savedState === "ON"; // If 'scan' is 'ON', set state to true, otherwise false
  });
  const navigate = useNavigate();
  const isScanOn = localStorage.getItem("scan") || "OFF"; // Fallback to "OFF" if "scan" is missing

  useEffect(() => {
    // Fetch expiry_days from localStorage
    const companyProfile = JSON.parse(localStorage.getItem("companyProfile"));

    if (companyProfile) {
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      if (expiryDaysObj) {
        setExpiryDays(Number(expiryDaysObj.feature_value)); // Ensure it's a number
      }
    }
  }, []);

  const handleFocus = (buttonRef) => {
    setFocusedButton(buttonRef);
  };

  const handleBlur = () => {
    setFocusedButton(null); // Clear the focus state when the button loses focus
  };

  const togglePopover = (index) => {
    if (popoverOpen === index) {
      setPopoverOpen(null);
    } else {
      setPopoverOpen(index);
    }
  };

  const togglePopoverDraft = () => setPopoverDraftOpen(!popoverDraftOpen);

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    const thisRow = updatedRows[index];

    const parseAndValidate = (val) => {
      const parsedValue = parseFloat(val);
      return isNaN(parsedValue) ? 0 : parsedValue;
    };

    if (field === "medicinetime") {
      const transformedData = Object.keys(value).map((time) => ({
        time: time,
        quantity: value[time].quantity.toString(),
        afterfood: value[time].afterfood,
      }));
      thisRow.medicinetime = transformedData;
    } else {
      if (field === "qty" && thisRow.availableQty < value) {
        setAlerts({
          type: "warning",
          message: "We do not have enough stocks which you required.",
        });
      }

      const companyProfile = JSON.parse(
        localStorage.getItem("companyProfile") || "[]"
      );
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      const expiryDays = expiryDaysObj
        ? parseInt(expiryDaysObj.feature_value)
        : 0; // Default to 7 days if not found
      // Check if the product's expiry date is within the 'expiryDays' threshold
      const today = moment();
      const expiry = moment(thisRow.expiryDate); // Assuming thisRow has the expiryDate field
      const diffInDays = expiry.diff(today, "days"); // Get the difference in days

      // Compare with expiryDays from local storage
      if (diffInDays <= expiryDays) {
        setAlerts({
          type: "warning",
          message: `Product is either expired or will expire in ${expiryDays} days.`,
        });
      }
      thisRow[field] = value;
    }
    // const qty = parseAndValidate(thisRow.qty);
    // const rate = parseAndValidate(thisRow.rate);
    // const amount = qty * rate;
    // thisRow.amount = amount > 0 ? amount : 0;

    // const discountAmount = parseAndValidate(
    //   thisRow.sellPriceApplicableDiscountAmount
    // );
    // thisRow.rate = rate > 0 ? rate - discountAmount : 0;
    // const productCost = thisRow.rate * qty;
    // thisRow.productCost = productCost > 0 ? productCost : 0;

    // const sgst = parseAndValidate(thisRow.sgst);
    // const cgst = parseAndValidate(thisRow.cgst);
    // const netAmount = thisRow.productCost;
    // thisRow.netAmount = netAmount > 0 ? netAmount : 0;
    // setRows(updatedRows);
    // Get parsed values for necessary fields
    const qty = parseAndValidate(thisRow.qty);
    const mrp = parseAndValidate(thisRow.mrp); // mrp as base price
    const discountAmount = parseAndValidate(
      thisRow.sellPriceApplicableDiscountAmount
    ); // Discount amount
    const sellPriceDiscountFixed = thisRow.sellPriceDiscountFixed; // Whether it's fixed or percentage

    let rate = thisRow.rate;

    // Calculate amount based on qty and updated rate
    const amount = qty * rate;
    thisRow.amount = amount > 0 ? amount : 0;
    // Update product cost based on the final rate and qty
    const productCost = rate * qty;
    thisRow.productCost = productCost > 0 ? productCost : 0;

    // Handling SGST and CGST, ensuring valid values
    const sgst = parseAndValidate(thisRow.sgst);
    const cgst = parseAndValidate(thisRow.cgst);

    // The netAmount is calculated as productCost
    const netAmount = thisRow.productCost;
    thisRow.netAmount = netAmount > 0 ? netAmount : 0;

    // Set the updated rows after all calculations
    setRows(updatedRows);
  };

  const updateRowProduct = (index, product) => {
    let suggestedProduct = {
      productId: product?.productId,
      productName: product?.productName,
      applicableDiscountAmount: product?.applicableDiscountAmount,
      available_qty: product?.available_qty,
      brand: product?.brand,
      categoryId: product?.categoryId,
      categoryName: product?.categoryName,
      cgst: product?.cgst,
      companyId: product?.companyId,
      sellPriceApplicableDiscountAmount:
        product?.sellPriceApplicableDiscountAmount,
      sellPriceDiscountPercentage:
        product?.sellPriceDiscountPercentage?.toFixed(2),
      sellPriceDiscountFixed: product?.sellPriceDiscountFixed?.toFixed(2),
      discountFixed: product?.discountFixed,
      discountPercentage: product?.discountPercentage,
      expiryDate: product?.expiryDate,
      gstRate: product?.gstRate,
      igstRate: product?.igstRate,
      isTax1Percentage: product?.isTax1Percentage,
      isTax2Percentage: product?.isTax2Percentage,
      isazureSearchData: product?.isazureSearchData,
      lead_time: product?.lead_time,
      maximumRetailPrice: product?.maximumRetailPrice,
      maximum_stock: product?.maximum_stock,
      measure: product?.measure,
      minimum_stock: product?.minimum_stock,
      mrpDiscountAmount: product?.mrpDiscountAmount,
      netPrice: product?.netPrice,
      netProductCost: product?.netProductCost,
      productCode: product?.productCode,
      productDescription: product?.productDescription,
      productImageName: product?.productImageName,
      productItemcode: product?.productItemcode,
      productShortcode: product?.productShortcode,
      productType: product?.productType,
      product_discount: product?.product_discount,
      reorder_point: product?.reorder_point,
      sgst: product?.sgst,
      igst: product?.igst,
      sizeDescription: product?.sizeDescription,
      sku: product?.sku,
      subCategoryId: product?.subCategoryId,
      subCategoryName: product?.subCategoryName,
      tax1: product?.tax1,
      tax1Description: product?.tax1Description,
      tax2: product?.tax2,
      tax2Description: product?.tax2Description,
      taxAmount: product?.taxAmount,
      un_used_quantity: product?.un_used_quantity,
      unit: product?.unit,
      unitItems: product?.unitItems,
      unitPrice: product?.unitPrice,
      upc_code: product?.upc_code,
      maximumRetailPrice: product?.maximumRetailPrice,
      sellPriceDiscountFixed:
        product?.sellPriceDiscountFixed > 0 ? true : false,
      cgstAmount: product?.cgstAmount,
      sgstAmount: product?.sgstAmount,
      buyPrice: product?.buyPrice,
    };

    setSuggestedProduct(suggestedProduct);

    if (!product?.productName || !product?.unitPrice || !product?.productId) {
      setAlerts({ type: "danger", message: "Please Select a Product" });
      return;
    }

    if (product?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const companyProfile = JSON.parse(
      localStorage.getItem("companyProfile") || "[]"
    );
    const expiryDaysObj = companyProfile.find(
      (item) => item.feature_key === "expiry_days"
    );
    const expiryDays = expiryDaysObj
      ? parseInt(expiryDaysObj.feature_value)
      : 0; // Default to 7 days if not found
    // Check if the product's expiry date is within the 'expiryDays' threshold
    const today = moment();
    const expiry = moment(product.expiryDate); // Assuming thisRow has the expiryDate field
    const diffInDays = expiry.diff(today, "days"); // Get the difference in days

    // Compare with expiryDays from local storage
    if (diffInDays <= expiryDays) {
      setAlerts({
        type: "warning",
        message: `Product is either expired or will expire in ${expiryDays} days.`,
      });
    }

    const isProductAlreadyAdded = rows.some(
      (row, i) => row.productId === product.productId && i !== index
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });

      const updatedRows = [...rows];
      updatedRows[index] = { productName: "", qty: 0, rate: 0 };
      setRows(updatedRows);
      return;
    }

    const productCostBeforeTaxCal =
      product?.unitPrice - (parseFloat(product?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * product?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * product?.tax1) / 100;

    const medicinetime = [
      {
        time: "morning",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "afternoon",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "evening ",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "night",
        quantity: 0,
        afterfood: "yes",
      },
    ];

    const sellPriceDiscountFixed = product?.sellPriceDiscountFixed; // Whether it's fixed or percentage
    let rate = 0;
    // Update the row with new product details
    if (sellPriceDiscountFixed) {
      // If the discount is fixed, subtract the fixed discount amount from mrp
      rate =
        product?.maximumRetailPrice -
        product?.sellPriceApplicableDiscountAmount;
    } else {
      // If the discount is a percentage, calculate the discount based on mrp
      const discountInPercentage =
        (product?.maximumRetailPrice *
          product?.sellPriceApplicableDiscountAmount) /
        100;
      rate = product?.maximumRetailPrice - discountInPercentage;
    }

    const newRow = {
      medicinetime: medicinetime,
      productName: product?.productName,
      productId: product?.productId,
      qty: 1,
      rate: rate,
      amount: product?.unitPrice,
      sellPriceApplicableDiscountAmount:
        parseFloat(product?.sellPriceApplicableDiscountAmount) || 0,
      sellPriceDiscountPercentage:
        parseFloat(product?.sellPriceDiscountPercentage) || 0,
      sellPriceDiscountFixed: product?.sellPriceDiscountFixed?.toFixed(2),
      productCost: productCostBeforeTaxCal,
      sgst: product?.sgst,
      cgst: product?.cgst,
      igst: product?.gstRate,
      availableQty: product?.available_qty,
      netAmount: productCostBeforeTaxCal,
      expiryDate: product?.expiryDate,
      maximumRetailPrice: product?.maximumRetailPrice,
      sellPriceDiscountFixed:
        product?.sellPriceDiscountFixed > 0 ? true : false,
      cgstAmount: product?.cgstAmount,
      sgstAmount: product?.sgstAmount,
      buyPrice: product?.buyPrice,
      mrpDiscountAmount: data?.mrpDiscountAmount,
    };
    const updatedRows = [...rows];
    updatedRows[index] = newRow;

    setRows(updatedRows);
  };

  const retriveProducts = async (queryText, type = "add", index = 0) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let productList = await getProductSearchList(queryText);

      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);

        if (productList.length === 1) {
          if (type === "add") {
            addRow(productList[0]);
            resetFields();
            setFilteredProducts([]);
          } else if (type === "update") {
            const updatedProduct = productList[0];
            updateRowProduct(index, updatedProduct);
            resetFields();
            setFilteredProducts([]);
          }
        }
      } else {
        /**
         * display no result found when no data get in response
         * Author: Devanshi Patel
         */
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                No result found
              </span>
            ),
          },
        ]);
      }
    } else {
      /**
       * Display error when only 1 character is type for product search auto complete
       * Author: Devanshi Patel
       */
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 500);
    }
  };

  const filterProduct = (event, type = "add", index = 0) => {
    const query = event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query, type, index);
    }, 1000); // 1s delay
  };

  const handleKeyPress = (event) => {
    if (event.altKey && event.key.toLowerCase() === "s") {
      const isChatBotActive =
        localStorage.getItem("isChatBotActive") === "true" || false;
      if (!isChatBotActive) {
        event.preventDefault();
        savingBill();
      }
    }
    if (event.altKey && event.key.toLowerCase() === "d") {
      const isChatBotActive =
        localStorage.getItem("isChatBotActive") === "true" || false;
      if (!isChatBotActive) {
        event.preventDefault();
        const draftButton = document.getElementById("draft");
        if (draftButton) {
          draftButton.click();
        }
      }
    }
  };

  const handleKeyDown = (e, nextRef) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent the default tab behavior
      if (nextRef.current) {
        nextRef.current.focus(); // Focus on the dropdown input
      }
    }
  };

  const savingBill = async () => {
    if (!selectedCustomerRef.current) {
      setAlerts({
        type: "danger",
        message: `Please select customer.`,
      });
      return;
    }

    if (!rowsRef.current || rowsRef.current.length === 0) {
      setAlerts({
        type: "danger",
        message: `Please select a product.`,
      });

      return;
    }

    if (
      rowsRef.current &&
      rowsRef.current.some((row) => row.availableQty <= 0)
    ) {
      setAlerts({
        type: "danger",
        message: `No quantity available for selected product/s, please remove.`,
      });
      return;
    }
    const companyProfile = JSON.parse(
      localStorage.getItem("companyProfile") || "[]"
    );
    const expiryDaysObj = companyProfile.find(
      (item) => item.feature_key === "expiry_days"
    );
    const expiryDays = expiryDaysObj
      ? parseInt(expiryDaysObj.feature_value)
      : 7; // Default to 7 days if not found

    const today = moment(); // Current date

    // Check if any product's expiryDate is expired or will expire within 'expiryDays'
    const expiredOrExpiringProduct = rowsRef.current?.some((row) => {
      const expiry = moment(row.expiryDate); // Assuming row has the expiryDate field
      const diffInDays = expiry.diff(today, "days"); // Get the difference in days

      // Check if product's expiry date is within the expiryDays threshold
      return diffInDays <= expiryDays;
    });

    // Trigger an alert if an expired or soon-to-expire product is found
    if (expiredOrExpiringProduct) {
      setAlerts({
        type: "danger",
        message: `Expiry Date reached for selected product/s, please remove.`,
      });
      return;
    }

    if (totalBeforeTaxRef.current <= 0) {
      setAlerts({
        type: "danger",
        message: `Select Product and quantity - Invoice Amount should be greater than 0.`,
      });
      return;
    }

    selectedCustomerRef.current;
    const updatedRows = rowsRef.current;
    const updatedSelectedCustomer = selectedCustomerRef.current;
    const productInfoData = updatedRows.map((item, index) => {
      let sellPriceCal;
      let price;
      let basePriceField;
      let disc;
      if (item?.sellPriceDiscountFixed) {
        disc = item?.sellPriceApplicableDiscountAmount;
      } else {
        disc =
          (item?.maximumRetailPrice * item?.sellPriceDiscountPercentage) / 100;
      }
      price = Number(item?.maximumRetailPrice - disc);

      let basePrice = Number(item?.maximumRetailPrice - disc);
      let basePriceForTax =
        Number(basePrice) /
        (1 +
          (Number(item?.cgst) + Number(item?.sgst) + Number(item?.gstRate)) /
            100);

      // Convert the number to a string and trim it to exactly 6 decimal places without rounding
      let basePriceForTaxString = basePriceForTax.toString();
      let [integerPart, decimalPart] = basePriceForTaxString.split(".");

      decimalPart = decimalPart ? decimalPart.substring(0, 6) : "000000"; // Keep only the first 6 decimal places
      let finalBasePriceForTax = `${integerPart}.${decimalPart}`;

      let cgstAmount =
        Number(finalBasePriceForTax) * (Number(item?.cgst) / 100);
      let sgstAmount =
        Number(finalBasePriceForTax) * (Number(item?.sgst) / 100);
      let igstAmount =
        Number(finalBasePriceForTax) * (Number(item?.gstRate) / 100);

      let totaltaxAmount = cgstAmount + sgstAmount + igstAmount;

      basePriceField = price - totaltaxAmount;
      sellPriceCal = (item?.maximumRetailPrice - disc - totaltaxAmount).toFixed(
        2
      );

      return {
        medicinetime: item.medicinetime,
        productId: item.productId,
        productName: item.productName,
        qty: item.qty,
        rate: basePriceField.toFixed(2),
        cgst: item.cgst?.toFixed(2),
        cgstType: "PERCENTAGE",
        sgst: item.sgst?.toFixed(2),
        sgstType: "PERCENTAGE",
        igst: item.gstRate?.toFixed(2),
        igstType: "PERCENTAGE",
        // sellPriceApplicableDiscountAmount:
        //   item.sellPriceApplicableDiscountAmount?.toFixed(2),
        // sellPriceDiscountPercentage:
        //   item.sellPriceDiscountPercentage?.toFixed(2),
        // sellPriceDiscountFixed: item?.sellPriceDiscountFixed?.toFixed(2),

        amount: item.amount?.toFixed(2),
        productCost: item.productCost?.toFixed(2),
        netAmount: parseFloat(item.productCost)?.toFixed(2),
        expiryDate: item.expiryDate,
        sgstTotalTaxAmount: sgstAmount?.toFixed(2),
        cgstTotalTaxAmount: cgstAmount?.toFixed(2),
        igstTotalTaxAmount: igstAmount?.toFixed(2),
        mrp: item.maximumRetailPrice?.toFixed(2),
        sellPriceDiscountFixed: item?.sellPriceDiscountFixed > 0 ? true : false,
        maximumRetailPrice: item?.maximumRetailPrice?.toString(),
        discountAmount: disc?.toString(),
        productDiscountType:
          item?.sellPriceDiscountFixed > 0
            ? "FIXED"
            : item.sellPriceDiscountPercentage > 0.0
            ? "PERCENTAGE"
            : "",
        productDiscountValue:
          item?.sellPriceDiscountFixed > 0
            ? item?.sellPriceApplicableDiscountAmount
            : item?.sellPriceDiscountPercentage > 0.0
            ? item?.sellPriceDiscountPercentage
            : 0,
        // productDiscountValue,
        // cgstAmount: item?.cgstAmount,
        // sgstAmount: item?.sgstAmount,
      };
    });

    const params = {
      companyId: Common.getCompanyId(),
      userId: Common.getUserId(),
      companyName: Common.getCompanyName(),
      companyAddress: Common.getCompanyAddress(),
      companyPhone: Common.getCompanyPhone(),
      companyEmail: Common.getCompanyEmail(),
      companyGSTN: Common.getGstIn(),
      footerText: "Disclaimer and Return Policy",
      invoiceFileName: billId + ".pdf",
      invoiceDate: Common.getFormatedDate(
        moment().utc(),
        "DD-MMMM-YYYY hh:mm:ss",
        true
      ),
      editableStatus: 1,
      refundPossible: 1,
      billId: billId,
      billDescription: "invoice",
      invoiceReferenceNumber: "",
      refundBillId: 0,
      customerId: updatedSelectedCustomer.customerID,
      customerName:
        customerDataRef.current.customerName != ""
          ? customerDataRef.current.customerName
          : updatedSelectedCustomer.customerName,
      customerAddress:
        customerDataRef.current.address != ""
          ? customerDataRef.current.address
          : updatedSelectedCustomer.address1,
      customerContact:
        customerDataRef.current.phoneNumber != ""
          ? customerDataRef.current.phoneNumber
          : updatedSelectedCustomer.phoneNumber,
      customerEmail: updatedSelectedCustomer.emailId,
      productInfos: productInfoData,
      subTotal: parseFloat(subTotalRef.current)?.toFixed(2),
      manualDiscount: manualDiscountTotalRef.current
        ? manualDiscountTotalRef.current.toFixed(2)
        : "0.00",
      manualDiscountAmount: manualDiscountAmountRef.current
        ? manualDiscountAmountRef.current?.toFixed(2)
        : "0.00",
      manualDiscountType: discountTypeRef.current ? "RUPEES" : "PERCENTAGE",
      totalBeforeTax: parseFloat(totalBeforeTaxRef.current)?.toFixed(2),
      totalTax: igstAmountRef.current?.toFixed(2),
      // gst: parseFloat(igstPercentage)?.toFixed(2),
      gst: 0,
      gstType: "PERCENTAGE",
      paidStatus: paymentDone,
      paymentMode: paymentMethod,
      grandTotal: parseFloat(totalBeforeTaxRef.current)?.toFixed(2),
    };
    // setLoader(true);

    const result = await saveInvoice(params);
    // Check if API response has an error
    // if (result.isError) {
    //   setAlerts({
    //     type: "danger",
    //     message: result.responseException.exceptionMessage.errorMessage,
    //   });
    //   setLoader(false); // Stop loader in case of an error
    //   return; // Exit the function
    // }
    setBillId(result.billId);
    setRecentBillId(result.billId);
    if (!isEmpty(result) && result.billId) {
      setLoadingPrint(false);
      setLoadingNew(false);
      await setAlerts({
        type: "success",
        message: `Invoice Reference No. ${result.invoiceReferenceNumber} / Bill No. ${result.billId} Saved Successfully.`,
      });

      setTimeout(async () => {
        setManualDiscount(0);
        manualDiscountTotalRef.current = 0;
        setBillId(0);
        setRows([]);
        setSelectedCustomer(null);
        setCustomerData({
          customerName: "",
          address1: "",
          address2: "",
          address: "",
          phoneNumber: "",
          emailId: "",
        });
      }, 1000);
    }
    // setLoader(false);
  };

  const formatDate = (dateString) => {
    if (dateString !== null) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
  };

  const addRow = (data) => {
    let suggestedProduct = {
      productId: data?.productId,
      productName: data?.productName,
      applicableDiscountAmount: data?.applicableDiscountAmount,
      available_qty: data?.available_qty,
      brand: data?.brand,
      categoryId: data?.categoryId,
      categoryName: data?.categoryName,
      cgst: data?.cgst,
      companyId: data?.companyId,
      sellPriceApplicableDiscountAmount:
        data?.sellPriceApplicableDiscountAmount,
      sellPriceDiscountPercentage:
        data?.sellPriceDiscountPercentage?.toFixed(2),
      sellPriceDiscountFixed: data?.sellPriceDiscountFixed?.toFixed(2),
      discountFixed: data?.discountFixed,
      discountPercentage: data?.discountPercentage,
      expiryDate: data?.expiryDate,
      gstRate: data?.gstRate,
      igstRate: data?.igstRate,
      isTax1Percentage: data?.isTax1Percentage,
      isTax2Percentage: data?.isTax2Percentage,
      isazureSearchData: data?.isazureSearchData,
      lead_time: data?.lead_time,
      maximumRetailPrice: data?.maximumRetailPrice,
      maximum_stock: data?.maximum_stock,
      measure: data?.measure,
      minimum_stock: data?.minimum_stock,
      mrpDiscountAmount: data?.mrpDiscountAmount,
      netPrice: data?.netPrice,
      netProductCost: data?.netProductCost,
      productCode: data?.productCode,
      productDescription: data?.productDescription,
      productImageName: data?.productImageName,
      productItemcode: data?.productItemcode,
      productShortcode: data?.productShortcode,
      productType: data?.productType,
      product_discount: data?.product_discount,
      reorder_point: data?.reorder_point,
      sgst: data?.sgst,
      igst: data?.gstRate,
      sizeDescription: data?.sizeDescription,
      sku: data?.sku,
      subCategoryId: data?.subCategoryId,
      subCategoryName: data?.subCategoryName,
      tax1: data?.tax1,
      tax1Description: data?.tax1Description,
      tax2: data?.tax2,
      tax2Description: data?.tax2Description,
      taxAmount: data?.taxAmount,
      un_used_quantity: data?.un_used_quantity,
      unit: data?.unit,
      unitItems: data?.unitItems,
      unitPrice: data?.unitPrice,
      upc_code: data?.upc_code,
      sellPriceDiscountFixed: data?.sellPriceDiscountFixed > 0 ? true : false,
      cgstAmount: data?.cgstAmount,
      sgstAmount: data?.sgstAmount,
      buyPrice: data?.buyPrice,
    };

    setSuggestedProduct(suggestedProduct);

    if (!data?.productName || !data?.unitPrice) {
      setAlerts({
        type: "danger",
        message:
          "Please select a product to the current row to add a new Product.",
      });
      return;
    }

    const medicinetime = [
      {
        time: "morning",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "afternoon",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "evening ",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "night",
        quantity: 0,
        afterfood: "yes",
      },
    ];

    const isProductAlreadyAdded = rows.some(
      (row) => row?.productName === data.productName
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });
      return;
    }

    if (data?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const companyProfile = JSON.parse(
      localStorage.getItem("companyProfile") || "[]"
    );
    const expiryDaysObj = companyProfile.find(
      (item) => item.feature_key === "expiry_days"
    );
    const expiryDays = expiryDaysObj
      ? parseInt(expiryDaysObj.feature_value)
      : 0;
    // Check if the product's expiry date is within the 'expiryDays' threshold
    const today = moment();
    const expiry = moment(data.expiryDate); // Assuming thisRow has the expiryDate field
    const diffInDays = expiry.diff(today, "days"); // Get the difference in days
    const formattedExpiryDate = new Date(data.expiryDate).toLocaleDateString();

    // Compare with expiryDays from local storage
    if (diffInDays <= expiryDays) {
      setAlerts({
        type: "warning",
        message: `The product ${data?.productName} expiry date is ${formattedExpiryDate}.`,
      });
    }

    const productCostBeforeTaxCal =
      data?.unitPrice - (parseFloat(data?.applicableDiscountAmount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * data?.sgst) / 100;
    const CGSTCost = (productCostBeforeTaxCal * data?.cgst) / 100;

    const newRow = {
      medicinetime: medicinetime,
      productId: data.productId,
      productDescription: data.productDescription,
      productName: data?.productName,
      categoryId: data.categoryId,
      qty: 1,
      availableQty: data?.available_qty,
      rate: data?.maximumRetailPrice - data?.sellPriceApplicableDiscountAmount,
      amount: data.unitPrice,
      sellPriceApplicableDiscountAmount:
        parseFloat(data?.sellPriceApplicableDiscountAmount) || 0,
      sellPriceDiscountPercentage:
        data?.sellPriceDiscountPercentage?.toFixed(2),
      sellPriceDiscountFixed: data?.sellPriceDiscountFixed?.toFixed(2),

      productCost: productCostBeforeTaxCal,
      sgst: data.sgst,
      cgst: data.cgst,
      gstRate: data.gstRate,
      netAmount: productCostBeforeTaxCal,
      expiryDate: data.expiryDate,
      maximumRetailPrice: data?.maximumRetailPrice,
      sellPriceDiscountFixed: data?.sellPriceDiscountFixed > 0 ? true : false,
      cgstAmount: data?.cgstAmount,
      sgstAmount: data?.sgstAmount,
      buyPrice: data?.buyPrice,
      mrpDiscountAmount: data?.mrpDiscountAmount,
    };

    const newRows = [...rows, newRow];
    setRows([...rows, newRow]);
    setFocusIndex(newRows.length - 1);
  };

  const deleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    setSelectedRecentProdIndex((prevIndex) => prevIndex - 1);
  };

  const resetFields = () => {
    setSelectedProducts(null);
  };

  const itemTemplate = (item) => {
    return (
      <div className="product-autocomplete-item-card">
        <p className="product-autocomplete-item-name">{item.productName}</p>
        <div className="product-autocomplete-item-details">
          <p>
            <span>Batch No:</span> {item?.batchNumber || ""}
          </p>
          <p>
            <span>Brand:</span> {item?.brand || ""}
          </p>
          <p>
            <span>UPC code:</span> {item?.upc_code || ""}
          </p>
          <p>
            <span>SKU:</span> {item?.sku || ""}
          </p>
          <p>
            <span>Available Qty:</span> {item?.available_qty || ""}
          </p>
          <p>
            <span>ExpiryDate:</span>{" "}
            <span
              style={{
                fontWeight: "500",
                color:
                  moment(item?.expiryDate).diff(moment(), "days") <= expiryDays
                    ? "red"
                    : "green",
              }}
            >
              {item?.expiryDate && formatDate(item?.expiryDate)}
            </span>
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedRecentProduct) {
      const isProductAlreadyAdded = rows.some(
        (row) => row?.productName == selectedRecentProduct.productName
      );

      if (isProductAlreadyAdded) {
        setAlerts({
          type: "warning",
          message: "Product is already added.",
        });
        return;
      }

      updateRowProduct(SelectedRecentProdIndex, selectedRecentProduct);
      setSelectedRecentProdIndex((prevIndex) => prevIndex + 1);
    }
  }, [selectedRecentProduct]);

  useEffect(() => {
    if (selectedCustomerID) {
      // Focus the AutoComplete input when selectedCustomer is available and not null
      autoCompleteRef.current?.focus();
    }
  }, [selectedCustomerID]);

  useEffect(() => {
    rowsRef.current = rows;
    selectedCustomerRef.current = selectedCustomer;
  }, [rows, selectedCustomer]);

  useEffect(() => {
    // Calculate subtotal
    const total = rows.reduce(
      (acc, row) => acc + parseFloat(row?.productCost),
      0
    );
    setSubTotal(total);
    subTotalRef.current = total;
    // Calculate manual discount amount
    const manualDiscountAmountCalculation = showInRupees
      ? parseFloat(manualDiscount) || 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0;

    setManualDiscountAmount(manualDiscountAmountCalculation);
    manualDiscountAmountRef.current = manualDiscountAmountCalculation;
    if (igstPercentage >= 0 && igstPercentage <= 100) {
      // Calculate IGST and update totals
      const igst = (totalWithDiscount * igstPercentage) / 100;
      setIgstAmount(igst);
      igstAmountRef.current = igst;
      setTotalBeforeTax(totalWithDiscount);
      totalBeforeTaxRef.current = totalWithDiscount;
      setTotalAmount(totalWithDiscount + igst);
      totalAmountRef.current = totalWithDiscount + igst;
    }

    // Handle Enter key press to perform save & new button action
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // Get isChatBotActive from localStorage or assume false if not set
        const isChatBotActive =
          localStorage.getItem("isChatBotActive") === "true" || false;

        // Proceed with saving the bill only if the chatbot is not active
        if (!isChatBotActive) {
          savingBill();
        }
      }
    };

    // Add keypress event listener
    window.addEventListener("keypress", handleKeyPress);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [
    rows,
    updateRowProduct,
    updateRow,
    manualDiscount,
    showInRupees,
    igstPercentage,
  ]);

  useEffect(() => {
    // This will set focus to the last input box in the array of refs whenever a new row is added.
    const lastRef = inputRefs.current[rows.length - 1];
    if (lastRef) {
      lastRef.focus();
      lastRef.select();
    }
  }, [rows]);

  useEffect(() => {
    if (isScanOn == "ON") {
      // Focus on the AutoComplete input when `isScanOn` is true
      autoCompleteRef.current.focus();
    } else if (focusIndex !== null && isScanOn == "OFF") {
      // Focus on the respective input if `isScanOn` is false and `focusIndex` is not null
      const input = document.getElementById(`qty-input-${focusIndex}`);
      if (input) {
        input.focus();
        input.select(); // Optionally select the text
      }
    }
  }, [focusIndex, isScanOn]); // Trigger when `focusIndex` or `isScanOn` changes

  useEffect(() => {
    const storedDrafts = JSON.parse(localStorage.getItem("drafts")) || [];
    setDrafts(storedDrafts);
  }, [isDraftCreated]);

  const deleteDraft = (draftName) => {
    const updatedDrafts = drafts.filter(
      (draft) => draft.draftName !== draftName
    );
    setDrafts(updatedDrafts);
    localStorage.setItem("drafts", JSON.stringify(updatedDrafts));
    setPopoverDraftOpen(false);
  };

  const editDraft = (draftName) => {
    const selectedDraft = drafts.filter(
      (draft) => draft.draftName === draftName
    );
    setPaymentMethod(selectedDraft[0].paymentMode);
    setSubTotal(selectedDraft[0].subTotal);
    subTotalRef.current = selectedDraft[0].subTotal;
    setTotalBeforeTax(selectedDraft[0].totalBeforeTax);
    totalBeforeTaxRef.current = selectedDraft[0].totalBeforeTax;
    setPaymentDone(selectedDraft[0].paidStatus);
    setManualDiscount(selectedDraft[0].manualDiscount);
    manualDiscountTotalRef.current = selectedDraft[0].manualDiscount;
    setIgstPercentage(selectedDraft[0].gst);
    setRows(selectedDraft[0].productInfos);
    setPopoverDraftOpen(false);
    setSelectedCustomer({
      customerID: selectedDraft[0].customerID,
      customerName: selectedDraft[0].customerName,
      customerEmail: selectedDraft[0].customerEmail,
      customerAddress: selectedDraft[0].customerAddress,
      customerContact: selectedDraft[0].customerContact,
    });
    deleteDraft(draftName);
  };

  const icon = popoverDraftOpen
    ? `${ERP_CDN_IMAGE_URL}/closeIcon.png`
      ? `${ERP_CDN_IMAGE_URL}/closeIcon.png`
      : closeIcon
    : `${ERP_CDN_IMAGE_URL}/allDrafts.png`
    ? `${ERP_CDN_IMAGE_URL}/allDrafts.png`
    : allDrafts;

  return (
    <>
      {/* <div className="title-row">
        <h4>Purchase Details</h4>
      </div> */}
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <div style={{ overflowX: "auto" }}>
            <table className="purchase-table">
              <thead>
                <tr>
                  <th className="product-name-col">Particulars</th>
                  <th className="qty-col">Quantity</th>
                  <th className="expiry-col Expiry Date">Expiry Date</th>
                  <th className="mrp-col">MRP</th>
                  <th className="discount-col">Discount ({currency})</th>
                  <th className="basePrice-col">Base Price</th>
                  {/* <th className="total-price-col">Total</th> */}
                  <th className="cgst-col">CGST (%)</th>
                  <th className="sgst-col">SGST (%)</th>
                  <th className="igst-col">IGST (%)</th>
                  <th className="rate-col">Price</th>
                  <th className="net-amount-col">Product Cost</th>
                  <th className="action-btn-col"></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  // Declare variables here before the return statement (JSX)
                  let sellPriceCal;
                  let price;
                  let basePriceField;
                  let disc;
                  if (row?.sellPriceDiscountFixed) {
                    disc = row?.sellPriceApplicableDiscountAmount;
                  } else {
                    disc =
                      (row?.maximumRetailPrice *
                        row?.sellPriceDiscountPercentage) /
                      100;
                  }
                  price = Number(row?.maximumRetailPrice - disc);

                  let basePrice = Number(row?.maximumRetailPrice - disc);
                  let basePriceForTax =
                    Number(basePrice) /
                    (1 +
                      (Number(row?.cgst) +
                        Number(row?.sgst) +
                        Number(row?.gstRate)) /
                        100);

                  // Convert the number to a string and trim it to exactly 6 decimal places without rounding
                  let basePriceForTaxString = basePriceForTax.toString();
                  let [integerPart, decimalPart] =
                    basePriceForTaxString.split(".");

                  decimalPart = decimalPart
                    ? decimalPart.substring(0, 6)
                    : "000000"; // Keep only the first 6 decimal places
                  let finalBasePriceForTax = `${integerPart}.${decimalPart}`;

                  let cgstAmount =
                    Number(finalBasePriceForTax) * (Number(row?.cgst) / 100);
                  let sgstAmount =
                    Number(finalBasePriceForTax) * (Number(row?.sgst) / 100);
                  let igstAmount =
                    Number(finalBasePriceForTax) * (Number(row?.gstRate) / 100);

                  let totaltaxAmount = cgstAmount + sgstAmount + igstAmount;

                  basePriceField = price - totaltaxAmount;
                  sellPriceCal = (
                    row?.maximumRetailPrice -
                    disc -
                    totaltaxAmount
                  ).toFixed(2);

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="product-name-col cutomize-form-control paddingLRZero">
                          <div className="product-seach-input">
                            <AdvanceFilterModal
                              addRow={addRow}
                              setSelectedProducts={setSelectedProducts}
                              index={index}
                              updateRowProduct={updateRowProduct}
                            />
                            <AutoComplete
                              className="custom-autocomplete"
                              value={row?.productName}
                              suggestions={filteredProduct}
                              completeMethod={(e) =>
                                filterProduct(e, "update", index)
                              }
                              field="productName"
                              itemTemplate={itemTemplate}
                              placeholder="Search by Product Name - at least 2 characters"
                              minLength={1}
                              onChange={(e) => {
                                updateRow(index, "productName", e.value);
                              }}
                              onSelect={(e) => {
                                updateRowProduct(index, e.value);
                              }}
                              pt={{
                                panel: {
                                  className: "autocomplete-Zindex",
                                },
                                item: {
                                  style: {
                                    padding: "10px",
                                  },
                                },
                              }}
                              delay={300}
                            />
                            <div
                              onClick={() => {
                                const url = `/admin${URL_INVENTORY_PRODUCTS_ADD}`;
                                const state = { productId: row?.productId }; // State object

                                // Serialize state as query parameters
                                const queryParams = new URLSearchParams(
                                  state
                                ).toString();

                                // Open the new tab with query parameters
                                window.open(`${url}?${queryParams}`, "_blank");
                              }}
                              style={{
                                backgroundColor: "#4a6cf7",
                                borderRadius: "8px",
                                padding: "6px",
                                color: "white",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "40px",
                                height: "40px",
                                cursor: "pointer",
                              }}
                            >
                              <LuPackagePlus size={18} />
                            </div>
                          </div>
                        </td>
                        <td className="qty-col cutomize-form-control">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              id={`qty-input-${index}`}
                              type="text"
                              value={row?.qty}
                              onChange={(e) => {
                                const newQty = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "qty",
                                  isNaN(newQty) ? "" : newQty
                                );
                              }}
                              maxLength={100}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                          </div>
                        </td>
                        <td
                          className="expiry-col cutomize-form-control p-0 paddingLRZero"
                          style={{
                            fontSize: "15px",
                            color:
                              moment(row?.expiryDate).diff(moment(), "days") <=
                              expiryDays
                                ? "red" // Optional visual indicator for disabled
                                : "green",
                          }}
                        >
                          {Common.getFormatedDate(
                            row?.expiryDate,
                            "DD MMM YYYY",
                            true
                          ) !== "Invalid date"
                            ? Common.getFormatedDate(
                                row?.expiryDate,
                                "DD MMM YYYY",
                                true
                              )
                            : "N/A"}
                        </td>
                        <td className="amount-col cutomize-form-control paddingLRZero">
                          {row?.maximumRetailPrice?.toFixed(2)}
                        </td>
                        <td className="discount-col cutomize-form-control paddingLRZero">
                          <div
                            className="custom-manual-discount-control"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Input
                              type="number"
                              className="manual-discount-input"
                              min="0"
                              maxLength={10}
                              // value={
                              //   isNaN(
                              //     row?.sellPriceDiscountFixed > 0
                              //       ? row?.sellPriceDiscountFixed
                              //       : row?.sellPriceDiscountPercentage
                              //   )
                              //     ? ""
                              //     : row?.sellPriceDiscountFixed > 0
                              //     ? row?.sellPriceDiscountFixed
                              //     : row?.sellPriceDiscountPercentage
                              // }
                              value={
                                isNaN(row?.sellPriceApplicableDiscountAmount)
                                  ? ""
                                  : row?.sellPriceDiscountFixed > 0
                                  ? row?.sellPriceApplicableDiscountAmount
                                  : row?.sellPriceDiscountPercentage
                              }
                              placeholder="Enter Discount"
                              onChange={(e) => {
                                const newDiscount = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  row?.sellPriceDiscountFixed > 0
                                    ? "sellPriceApplicableDiscountAmount"
                                    : "sellPriceDiscountPercentage",
                                  Math.max(
                                    0,
                                    isNaN(newDiscount) ? 0 : newDiscount
                                  )
                                );
                              }}
                              // onChange={(e) => {
                              //   const newRate = parseFloat(e.target.value);
                              //   updateRow(
                              //     index,
                              //     "rate",
                              //     Math.max(0, isNaN(newRate) ? 0 : newRate)
                              //   );
                              // }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                              style={{
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white", // Grey background for disabled, white otherwise
                                width: "60px",
                                height: "35px",
                              }}
                              // onBlur={() => calculateSellPriceDiscount()}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "-" ||
                                  e.key === "e" ||
                                  e.key === "+"
                                ) {
                                  e.preventDefault(); // Prevent negative, exponential, and "+" symbols
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            <Input
                              disabled
                              id="discount"
                              name="sellPriceDiscountFixed"
                              type="select"
                              className="type-dropdown"
                              style={{
                                width: "100px",
                                height: "35px",
                                padding: "0px",
                              }}
                              value={
                                row?.sellPriceDiscountFixed ? "true" : "false"
                              }
                              onChange={(e) => {
                                const sellPriceDiscountFixed = e.target.value;
                                updateRow(
                                  index,
                                  "sellPriceDiscountFixed",
                                  sellPriceDiscountFixed
                                );
                              }}
                              // onBlur={() => calculateSellPriceDiscount()}
                            >
                              <option value={true}>Fixed</option>
                              <option value={false}>Percentage</option>
                            </Input>

                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                     <p style="margin: 0; padding: 0;">
  Discount: (${currency}) ${disc?.toFixed(2)}
</p>
                     </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="basePrice-col cutomize-form-control paddingLRZero">
                          {basePriceField?.toFixed(2)}
                        </td>

                        <td className="cgst-col cutomize-form-control paddingLRZero">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              onKeyDown={(e) =>
                                handleKeyDown(e, autoCompleteRef)
                              }
                              ref={cgstRef}
                              value={row?.cgst || 0}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "cgst",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                              readOnly
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                     <p style="margin: 0; padding: 0;">
  CGST: (${currency}) ${cgstAmount?.toFixed(2)}
</p>
                     </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="sgst-col cutomize-form-controlpaddingLRZero">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              readOnly
                              value={row?.sgst || 0}
                              ref={sgstRef}
                              // onKeyDown={(e) => handleKeyDown(e, sgstRef)}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "sgst",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                                                              <p style="margin: 0; padding: 0;">
  SGST: (${currency}) ${sgstAmount?.toFixed(2)}
</p>
                                                            </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="igst-col cutomize-form-controlpaddingLRZero">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              readOnly
                              value={row?.gstRate || 0}
                              ref={igstRef}
                              // onKeyDown={(e) => handleKeyDown(e, sgstRef)}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "gstRate",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                                                             <p style="margin: 0; padding: 0;">
                                IGST: (${currency}) ${igstAmount?.toFixed(2)}
</p>
                                                            </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="rate-col cutomize-form-control paddingLRZero">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Input
                              className="custom-number-input"
                              type="number"
                              readOnly
                              value={price?.toFixed(2)}
                              onChange={(e) => {
                                const newRate = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "rate",
                                  Math.max(0, isNaN(newRate) ? 0 : newRate)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                              style={{
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white", // Grey background for disabled, white otherwise
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">

                                 <p style="margin: 0; padding: 0;">
                                Sell Price: (${currency}) ${sellPriceCal}
</p>
                                                             <p style="margin: 0; padding: 0;">
                                Buy Price: (${currency}) ${(() => {
                                  return row?.buyPrice?.toFixed(2);
                                })()}
</p>
 <p style="margin: 0; padding: 0;">
                                Profit: (${currency}) ${(
                                  sellPriceCal - row?.buyPrice
                                ).toFixed(2)}
</p>
                                                            </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="net-amount-col cutomize-form-control paddingLRZero">
                          <span
                            data-tooltip-id="net-amount-tooltip"
                            data-tooltip-html={(() => {
                              // Calculate rate
                              let rate;
                              if (row?.sellPriceDiscountFixed) {
                                rate =
                                  row?.maximumRetailPrice -
                                  row?.sellPriceApplicableDiscountAmount;
                              } else {
                                rate =
                                  row?.maximumRetailPrice -
                                  (row?.maximumRetailPrice *
                                    row?.sellPriceDiscountPercentage) /
                                    100;
                              }
                              rate =
                                rate >= 0 ? parseFloat(rate.toFixed(2)) : 0; // Ensure `rate` is numeric

                              // Generate tooltip HTML
                              return `<span style="color: white; margin: 0; padding: 0; display: block;">
                            <p style="margin: 0; padding: 0;">CGST: (${currency}) ${cgstAmount?.toFixed(
                                2
                              )}</p>
                            <p style="margin: 0; padding: 0;">SGST: (${currency}) ${sgstAmount?.toFixed(
                                2
                              )}</p>
                            <p style="margin: 0; padding: 0;">IGST: (${currency}) ${igstAmount?.toFixed(
                                2
                              )}</p>
                        </span>`;
                            })()}
                          >
                            {(() => {
                              // Calculate rate and total
                              let rate;
                              if (row?.sellPriceDiscountFixed) {
                                rate =
                                  row?.maximumRetailPrice -
                                  row?.sellPriceApplicableDiscountAmount;
                              } else {
                                rate =
                                  row?.maximumRetailPrice -
                                  (row?.maximumRetailPrice *
                                    row?.sellPriceDiscountPercentage) /
                                    100;
                              }
                              rate =
                                rate >= 0 ? parseFloat(rate.toFixed(2)) : 0; // Ensure `rate` is numeric
                              const total = row?.qty * rate; // Calculate total

                              // Store the calculated total in row?.productCost
                              row.productCost = total >= 0 ? total : 0; // Ensure it's not negative

                              return total?.toFixed(2); // Return total formatted to 2 decimal places
                            })()}
                          </span>
                          <Tooltip
                            style={{
                              backgroundColor: "#434ce6",
                              color: "white",
                            }}
                            id="net-amount-tooltip"
                            place="bottom"
                          />
                        </td>
                        <td className="action-btn-col paddingLRZero">
                          <FaRegTrashCan
                            className="create-bill-icon delete-row-icon"
                            id={`Popover-${index}`}
                            onClick={() => togglePopover(index)}
                          />
                          <Popover
                            // placement="bottom"
                            isOpen={popoverOpen === index}
                            target={`Popover-${index}`}
                            toggle={() => togglePopover(index)}
                            className="draft-popover"
                          >
                            <PopoverBody
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                  wordWrap: "break-word",
                                }}
                              >
                                Are you sure you want to delete this item?
                              </span>
                              <div className="d-flex justify-content-center align-items-center gap-5">
                                <Button
                                  className="custom-primary-button"
                                  size="sm"
                                  onClick={() => {
                                    deleteRow(index);
                                    togglePopover(index);
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="custom-primary-button add-draft-btn"
                                  size="sm"
                                  onClick={() => togglePopover(index)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </PopoverBody>
                          </Popover>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td className="product-name-col paddingLRZero">
                    <div className="product-seach-input">
                      <AdvanceFilterModal
                        addRow={addRow}
                        setSelectedProducts={setSelectedProducts}
                      />
                      <AutoComplete
                        ref={autoCompleteRef}
                        onKeyDown={(e) => handleKeyDown(e, paymentModeRef)}
                        className="custom-autocomplete customize-autocomplete"
                        value={selectedProducts}
                        suggestions={filteredProduct}
                        completeMethod={filterProduct}
                        field="productName"
                        placeholder="Search by Product Name"
                        minLength={1}
                        itemTemplate={itemTemplate}
                        onChange={(e) => {
                          setSelectedProducts(e.value);
                        }}
                        onSelect={(e) => {
                          addRow(e.value);
                          resetFields();
                        }}
                        pt={{
                          panel: {
                            className: "autocomplete-Zindex",
                          },
                          item: {
                            style: {
                              padding: "10px",
                            },
                          },
                        }}
                        delay={300}
                      />
                    </div>
                  </td>
                  <td className="qty-col cutomize-form-control paddingLRZero">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        style={{
                          height: "34.5px",
                          fontSize: "14px",
                          paddingRight: "8px",
                          width: "60%",
                          boxSizing: "border-box",
                        }}
                        className="custom-number-input"
                        type="text"
                        value={0}
                        min="0"
                        maxLength={100}
                      />
                    </div>
                  </td>
                  <td
                    className="expiry-col cutomize-form-control p-0"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    N/A
                  </td>
                  <td className="mrp-col">{0}</td>
                  <td className="discount-col cutomize-form-control paddingLRZero">
                    {0}
                  </td>
                  <td className="basePrice-col cutomize-form-control paddingLRZero">
                    {0}
                  </td>

                  {/* <td className="total-price-col cutomize-form-control">0 </td> */}
                  <td className="cgst-col cutomize-form-control paddingLRZero">
                    0
                  </td>
                  <td className="sgst-col cutomize-form-control paddingLRZero">
                    0
                  </td>
                  <td className="igst-col cutomize-form-control paddingLRZero">
                    0
                  </td>
                  <td className="rate-col cutomize-form-control paddingLRZero">
                    {0}
                  </td>

                  <td className="net-amount-col cutomize-form-control paddingLRZero">
                    0
                  </td>
                  <td className="action-btn-col paddingLRZero">
                    <IoAddCircleOutline
                      className="create-bill-icon"
                      onClick={addRow}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="invoice-summary-container">
            <Row className="w-100">
              <Col xs={12} sm={12} md={12} lg={8}>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center mb-2">
                      <h4 className="title">Invoice & Payment</h4>
                    </div>
                  </Col>
                </Row>
                <Row style={{ rowGap: "10px" }}>
                  <Col sx={12} sm={12} md={4} lg={3}>
                    <div className="invoice-form-control">
                      <label>Payment Mode</label>
                      <select
                        id="payment"
                        name="payment"
                        value={paymentMethod}
                        onKeyDown={(e) => handleKeyDown(e, manualDiscountRef)}
                        className="invoice-payment-mode-control"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        ref={paymentModeRef}
                        onFocus={() => setIsDropdownFocused(true)} // Set focus state
                        onBlur={() => setIsDropdownFocused(false)} // Reset focus state
                        style={{
                          width: "180px",
                          border: isDropdownFocused
                            ? "2px solid blue"
                            : "1px solid gray", // Conditional border style
                          outline: "none", // Remove default outline
                          boxShadow: isDropdownFocused
                            ? "0 0 5px rgba(0, 0, 255, 0.5)"
                            : "none", // Optional: add shadow for better highlight
                        }}
                      >
                        <option value="cash">Cash</option>
                        <option value="credit/ debit card">
                          Credit/Debit Card
                        </option>
                        <option value="upi">UPI</option>
                        <option value="net banking">Net Banking</option>
                      </select>
                    </div>
                  </Col>
                  <Col sx={12} sm={12} md={4} lg={4}>
                    <div className="invoice-form-control">
                      <label htmlFor="">Payment Details</label>
                      <Input
                        id="comment"
                        name="comment"
                        placeholder="Write down.."
                        type="textarea"
                        maxLength={200}
                        rows={2}
                        className="invoice-comment-control"
                      />
                    </div>
                  </Col>
                  <Col sx={12} sm={12} md={4} lg={4}>
                    <div className="invoice-form-control">
                      <label>
                        Manual Discount ({showInRupees ? currency : "%"})
                      </label>
                      <div className="custom-manual-discount-control">
                        <input
                          type="number"
                          min="0"
                          max={showInRupees ? subTotal ?? 0 : 100} // Dynamic max value based on the type
                          value={manualDiscount === "" ? "0" : manualDiscount} // Display 0 when input is empty
                          style={{ width: "130px" }}
                          className="manual-discount-input"
                          onChange={(e) => {
                            let value = parseFloat(e.target.value);

                            // If the input is empty, set value to ""
                            if (isNaN(value) || e.target.value === "") {
                              value = "";
                            }

                            const subtotal = subTotal ?? 0;

                            if (!showInRupees) {
                              if (value > 100) {
                                value = 100;
                              } else if (value < 0) {
                                value = 0;
                              }
                            }

                            // Ensure the discount does not exceed the subtotal only when discount type is Fixed
                            if (showInRupees && value > subtotal) {
                              value = subtotal;
                            }

                            // If value is empty, set it to 0
                            if (value === "") {
                              value = 0;
                            }

                            setManualDiscount(value);
                            manualDiscountTotalRef.current = value;
                          }}
                          ref={manualDiscountRef}
                          onKeyDown={(e) => {
                            if (
                              e.key === "-" ||
                              e.key === "e" ||
                              e.key === "+"
                            ) {
                              e.preventDefault(); // Prevent negative, exponential, and "+" symbols
                            }
                            handleKeyDown(e, igstInputRef);
                          }}
                          onPaste={(e) => {
                            const pastedValue = e.clipboardData.getData("Text");
                            if (
                              pastedValue.includes("-") ||
                              pastedValue.includes("+")
                            ) {
                              e.preventDefault(); // Prevent pasting negative or "+" values
                            }
                          }}
                        />

                        <Input
                          id="discount"
                          name="discount"
                          type="select"
                          value={showInRupees}
                          className="type-dropdown"
                          onChange={(e) => {
                            const newShowInRupees = !showInRupees;
                            discountTypeRef.current = newShowInRupees;
                            setShowInRupees(newShowInRupees);

                            // Reset manual discount to 0 when switching types
                            setManualDiscount(0);
                            manualDiscountTotalRef.current = 0; // Update the ref if necessary
                          }}
                        >
                          <option value={true}>Fixed</option>
                          <option value={false}>Percentage</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  {/* <Col sx={12} sm={12} md={4} lg={4}>
                <div className="invoice-form-control">
                  <label htmlFor="">IGST (%)</label>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="manual-igst-minus-btn"
                      onClick={() => setIgstPercentage(igstPercentage - 1)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={igstPercentage || 0}
                      className="manual-igst-input"
                      onChange={(e) => {
                        setIgstPercentage(e.target.value);
                      }}
                      ref={igstInputRef}
                      onKeyDown={(e) => handleKeyDown(e, saveBtnRef)}
                    />
                    <button
                      className="manual-igst-plus-btn"
                      onClick={() => setIgstPercentage(igstPercentage + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </Col> */}
                </Row>
                <Row className="my-3" style={{ rowGap: "10px" }}>
                  {/* <Col sm={12} md={6}>
                    <div className="invoice-form-control">
                      <label htmlFor="">Payment Details</label>
                      <Input
                        id="comment"
                        name="comment"
                        placeholder="Write down.."
                        type="textarea"
                        maxLength={200}
                        rows={1}
                        className="invoice-comment-control"
                      />
                    </div>
                  </Col> */}
                  <Col
                    sm={12}
                    md={6}
                    className="d-flex align-items-end justify-content-start pb-2"
                    style={{ gap: "10px" }}
                  >
                    <input
                      type="checkbox"
                      id="custom-checkbox"
                      className="custom-checkbox"
                      checked={paymentDone}
                      onChange={() => {
                        setPaymentDone((prev) => !prev);
                      }}
                    />
                    <p className="received-payment-label">Received Payment</p>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-column justify-content-between"
              >
                <Row style={{ gap: "15px" }}>
                  <Col md={12} className="invoice-summary-data-row">
                    <p>Sub Total</p>
                    <p style={{ color: "#483EB6" }}>
                      {(subTotal ?? 0)?.toFixed(2)}
                    </p>
                  </Col>
                  {/* New Manual Discount Row */}
                  <Col md={12} className="invoice-summary-data-row">
                    <p>Manual Discount</p>
                    <p style={{ color: "#FF0000" }}>
                      {"- " +
                        (manualDiscountAmountRef.current ?? 0)?.toFixed(2)}
                    </p>
                  </Col>

                  <Col md={12} className="invoice-summary-data-row">
                    <p
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      Net Payable Amount
                    </p>
                    <p
                      style={{
                        color: "#58AD6B",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      {"(" +
                        (currency ?? "0") +
                        ") " +
                        (getSymbolFromCurrency(currency) ?? "") +
                        " " +
                        (totalBeforeTax ?? 0)?.toFixed(2)}{" "}
                    </p>
                    {/* <p style={{ color: "#58AD6B" }}>{totalBeforeTax?.toFixed(2)}</p> */}
                  </Col>
                  {/*
              <Col md={12} className="invoice-summary-data-row">
                <p>TAX Applicable</p>
                <p>{igstAmount?.toFixed(2)}</p>
              </Col> */}
                  {/* <Col
                md={12}
                className="invoice-summary-data-row invoice-summary-grand-total-row"
              >
                <p>Net Payable Amount</p>
                <p>
                  {" "}
                  {"(" +
                    (currency ?? "0") +
                    ") " +
                    (getSymbolFromCurrency(currency) ?? "") +
                    " " +
                    (totalAmount ?? 0)?.toFixed(2)}{" "}
                </p>
              </Col> */}
                </Row>
                <Row className="mt-4 align-items-center justify-content-end">
                  <Col
                    xs={12}
                    md={12}
                    className="create-bill-footer-btn-container"
                  >
                    {/* Keyboard Shortcuts [start] */}
                    <button
                      data-tooltip-id="create-bill-shortcuts"
                      className="add-customer-btn create-bill-help-btn"
                      type="button"
                    >
                      <MdContactSupport size={16} />
                    </button>
                    <Tooltip id="create-bill-shortcuts">
                      <div className="tooltip-content">
                        <Row>
                          <Col xs={6} className="key">
                            <span>Alt</span>+<span>S</span>
                          </Col>
                          <Col xs={6}>Save Invoice/Bill</Col>
                        </Row>
                        <Row>
                          <Col xs={6} className="key">
                            <span>Alt</span>+<span>D</span>
                          </Col>
                          <Col xs={6}>Save Invoice as Draft</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="key">
                            <span>Alt</span>+<span>A</span>
                          </Col>
                          <Col xs={6}>Add Customer</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="key">
                            <span>Alt</span>+<span>M</span>
                          </Col>
                          <Col xs={6}>Modify Customer</Col>
                        </Row>
                      </div>
                    </Tooltip>
                    {/* Keyboard Shortcuts [end] */}

                    <div style={{ position: "inherit" }}>
                      <Button
                        title="All Drafts"
                        id="PopoverButton"
                        type="button"
                        className="btn-fixed"
                        onClick={togglePopoverDraft}
                      >
                        <img
                          src={icon}
                          alt="Toggle"
                          className={`draft-icon ${
                            popoverDraftOpen ? "rotate" : ""
                          }`}
                        />
                      </Button>
                      <Popover
                        // placement="top"
                        isOpen={popoverDraftOpen}
                        target="PopoverButton"
                        toggle={togglePopoverDraft}
                        className="draft-popover"
                      >
                        <PopoverHeader>All Drafts</PopoverHeader>
                        <PopoverBody className="draft-popover-body">
                          {drafts.length === 0 && <>No Drafts Present</>}
                          {drafts.map((draft, index) => (
                            <Card key={index} className="draft-card">
                              <CardBody>
                                <Row className="draft-card-row">
                                  <Col sm={12}>
                                    <h6 className="draft-title">
                                      {draft.draftName}
                                    </h6>
                                    <span>
                                      {Common.getFormatedDate(
                                        draft.savedAt,
                                        "ddd MM, YYYY - hh:mm A",
                                        true
                                      )}
                                    </span>
                                  </Col>
                                  <Col sm={12} className="draft-btns">
                                    <div
                                      onClick={() => editDraft(draft.draftName)}
                                      className="action-icon"
                                    >
                                      <FaUpload size={15} />
                                    </div>
                                    <div
                                      onClick={() =>
                                        deleteDraft(draft.draftName)
                                      }
                                      className="action-icon"
                                    >
                                      <FaTrash size={15} />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          ))}
                        </PopoverBody>
                      </Popover>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ gap: "10px" }}
                    >
                      <CustomButton
                        ref={saveBtnRef}
                        onKeyDown={(e) => handleKeyDown(e, draftBtnRef)}
                        onFocus={() => handleFocus(saveBtnRef)}
                        onBlur={handleBlur}
                        title="Alter + S"
                        label={
                          loadingNew || loadingPrint ? (
                            <>
                              <span className="loading-text">
                                Saving Bill...
                              </span>{" "}
                            </>
                          ) : (
                            <>Save Invoice</>
                          )
                        }
                        disabled={loadingNew || loadingPrint}
                        onClick={() => savingBill()}
                        style={{
                          fontWeight: "bold",
                          border:
                            focusedButton === saveBtnRef
                              ? "2px solid blue"
                              : "1px solid gray", // Highlight border when focused
                          outline: "none", // Remove default outline
                          boxShadow:
                            focusedButton === saveBtnRef
                              ? "0 0 5px rgba(0, 0, 255, 0.5)"
                              : "none", // Optional: add shadow for better highlight
                        }}
                      />

                      <CustomButton
                        ref={draftBtnRef}
                        onFocus={() => handleFocus(draftBtnRef)}
                        onBlur={handleBlur}
                        title="Alter + D"
                        label="Save as Draft"
                        id="draft"
                        type="button"
                        onClick={createDraft}
                        customStyle="add-draft-btn"
                        style={{
                          fontWeight: "bold",
                          // marginLeft: "10px",
                          border:
                            focusedButton === draftBtnRef
                              ? "2px solid blue"
                              : "1px solid gray", // Highlight border when focused
                          outline: "none", // Remove default outline
                          boxShadow:
                            focusedButton === draftBtnRef
                              ? "0 0 5px rgba(0, 0, 255, 0.5)"
                              : "none", // Optional: add shadow for better highlight
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Dialog
                showHeader={true}
                visible={visible}
                onHide={() => setVisible()}
                style={{ width: "90vw" }}
                breakpoints={{
                  "960px": "75vw",
                  "641px": "100vw",
                }}
              >
                <InvoiceBillStep />
              </Dialog>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default BillSummary;
