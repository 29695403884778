import Common from "helpers/Common";
import { useEffect, useRef, useState } from "react";
// reactstrap components
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoginStore } from "hooks/Login";
import { useSubscriptionStore } from "hooks/Subscription";
import moment from "moment";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import { isEmpty } from "underscore";
import RazorPayPayment from "views/payment/RazorPayPayment";
import "../../assets/styles/subscriptions/subscription.css";
import ChildLoader from "components/Loader/ChildLoader";

const Subscription = () => {
  const [
    {},
    { getCompanySubscription },
    {
      createCompanySubscription,
      updateCompanySubscription,
      createCustomPlanSubscription,
    },
  ] = useSubscriptionStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [currentSubscriptionDetail, setCurrentSubscriptionDetail] = useState(
    {}
  );
  const [selectedSubscriptionDetail, setSelectedSubscriptionDetail] = useState(
    {}
  );
  const [showModal, setShowModal] = useState(false);
  const [TCChecked, setTCChecked] = useState(false);
  const [showCustomPlanConirmModal, setshowCustomPlanConirmModal] =
    useState(false);
  const [isCustomPlanPayment, setisCustomPlanPayment] = useState(false);
  const [customPlanAmount, setcustomPlanAmount] = useState({
    amount: 1000,
    errorMessage: "", // To store error message for validation
  });
  const [{}, { getCompanySubscriptionDetails }, {}] = useSubscriptionStore();
  const [subData, setsubData] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const amountCustom = useRef(1000);
  const iconTrue = <FontAwesomeIcon icon={faCheck} className="icon-true" />;
  const iconFalse = <FontAwesomeIcon icon={faTimes} className="icon-false" />;

  const renderIcon = (value) => {
    if (value === "Yes") {
      return iconTrue;
    } else if (value === "No") {
      return iconFalse;
    } else {
      return null; // Return null for numeric values
    }
  };

  const toggle = () => {
    setShowModal(!showModal);
    if (showCustomPlanConirmModal) setshowCustomPlanConirmModal(false);
    setTCChecked(false);
    setcustomPlanAmount((prevState) => ({
      ...prevState,
      amount: 1000,
      errorMessage: "",
    }));
    amountCustom.current = 1000;
  };

  const toggleConfirmCustom = () => {
    setshowCustomPlanConirmModal(!showCustomPlanConirmModal);
    setTCChecked(false);
    setcustomPlanAmount((prevState) => ({
      ...prevState,
      amount: 1000,
      errorMessage: "",
    }));
    amountCustom.current = 1000;
  };

  useEffect(() => {
    const getCurrentSubscription = async () => {
      let res = await getCurrentSubscriptionDetail();

      if (res && isEmpty(res)) {
        await createCompanySubscription({
          subscriptionId: 1,
          totalAmount: 0,
          paidStatus: false,
          paidDate: moment().utc(),
          transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
          description: "subscription create",
        });
      }
    };

    setLoadingFilter(true);
    const getCurrentSubscriptionDetailsData = async () => {
      let res = await getCompanySubscriptionDetails();
      if (res && !isEmpty(res)) {
        setsubData(res);
      }
      setLoadingFilter(false);
    };

    getCurrentSubscription();
    getCurrentSubscriptionDetailsData();
  }, []);

  const getCurrentSubscriptionDetail = async () => {
    let subscriptionData = await getCompanySubscription();
    setCurrentSubscriptionDetail(subscriptionData);
    setisCustomPlanPayment(false);
    return subscriptionData;
  };

  const manageUpgrade = (subscriptionData) => {
    setSelectedSubscriptionDetail(subscriptionData);
    // createCompanySubscription({
    //   "subscriptionId": subscriptionData.subscriptionId,
    //   "totalAmount": subscriptionData.amount,
    //   "paidStatus": false,
    //   "paidDate": moment().utc(),
    //   "transactionId": Common.getFormatedDate(moment(), 'YYYYMMDDHHmmssSSS'),
    //   "description": "subscription update with false status"
    // })
    toggle();
  };

  const manageRenewPayment = () => {
    createCompanySubscription({
      subscriptionId: currentSubscriptionDetail.subscriptionId,
      totalAmount: currentSubscriptionDetail.paidAmount_Subscrption,
      paidStatus: true,
      paidDate: Common.getFormatedDate(
        moment(currentSubscriptionDetail.endDate).add(1, "d"),
        "DD-MM-YYYY"
      ),
      transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
      description: "subscription renew with true status",
    });
    setAlerts({
      type: "success",
      message: "Your subscription renew successfully.",
    });
  };

  const manageSuccessPayment = async (isRenew = false) => {
    if (isRenew) {
      await manageRenewPayment();
      await getCurrentSubscriptionDetail();
    }
    if (isCustomPlanPayment) {
      await createCustomPlanSubscription({
        subscriptionId: selectedSubscriptionDetail.subscriptionId,
        rechargAmount: amountCustom.current,
        paidStatus: true,
        transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
        description: "subscription update with true status",
      });
      setAlerts({
        type: "success",
        message: "Your subscription renew successfully.",
      });
      await getCurrentSubscriptionDetail();
      toggle();
    } else {
      await createCompanySubscription({
        subscriptionId: selectedSubscriptionDetail.subscriptionId,
        totalAmount: selectedSubscriptionDetail.amount,
        paidStatus: true,
        paidDate: moment().utc(),
        transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
        description: "subscription update with true status",
      });
      setAlerts({
        type: "success",
        message: "Your subscription renew successfully.",
      });
      await getCurrentSubscriptionDetail();
      toggle();
    }
  };

  const values = [
    {
      feature: "Inventory count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 150000,
      enterprise: 200000,
    },
    {
      feature: "Maximum Invoice/Bill count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 150000,
      enterprise: 200000,
    },
    {
      feature: "SMS, Email and WhatsApp notification count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 150000,
      enterprise: 200000,
    },
    {
      feature: "Inventory Management",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Customizable Template",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    // {
    //   feature: "Historical Analytics",
    //   freeTier: "Yes",
    //   economicalCustom: "Yes",
    //   smallBusiness: "Yes",
    //   enterprise: "Yes",
    // },
    // {
    //   feature: "Bill Template and Recent Product List",
    //   freeTier: "Yes",
    //   economicalCustom: "Yes",
    //   smallBusiness: "Yes",
    //   enterprise: "Yes",
    // },
    {
      feature: "Cloud Web and Mobile App (Android and iOS)",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    // {
    //   feature: "Advanced Permissions",
    //   freeTier: "Yes",
    //   economicalCustom: "Yes",
    //   smallBusiness: "Yes",
    //   enterprise: "Yes",
    // },
    {
      feature: "Payment Reports and Payment Status",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Analytics",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Unlimited Storage for Product Images",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Re-Order and Demand Prediction",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "99.9% Uptime SLA",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    // {
    //   feature: "Premium Support (Standard Hours)",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // {
    //   feature: "Core EMR/EHR Functionality",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // {
    //   feature: "Patient Scheduling & Billing",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // // Add more rows as needed
  ];

  const handleAmountChange = (e) => {
    let amountT = e.target.value.trim(); // Trim whitespace from input

    // Validate input format: must be numeric
    if (!/^\d*$/.test(amountT)) {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT === "" ? 1000 : parseInt(amountT) || 0, // Default to 1000 if empty or non-numeric
        errorMessage: "Amount must be numeric.",
      });
    } else {
      // Check if amount exceeds 6 digits
      if (amountT.length > 6) {
        amountT = amountT.slice(0, 6); // Truncate input to first 6 characters
      }
    }

    // Check if the input is empty or non-numeric
    if (isNaN(amountT) || e.target.value.trim() === "") {
      amountT = 1000; // Set default value if input is empty or non-numeric
    }

    if (amountT < 1000 || amountT > 100000) {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT,
        errorMessage: "Amount must be between 1000 and 100000.",
      });
      setTCChecked(false);
      amountCustom.current = amountT;
    } else {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT,
        errorMessage: "",
      });
      amountCustom.current = amountT;
    }
  };

  const PLANS = [
    {
      subscriptionId: 1,
      name: "Free Trial",
      price: 0,
      duration: "For 1 month",
      inventoryCount: 100,
      billCount: 100,
      sms: 100,
    },
    {
      subscriptionId: 2,
      name: "Small Business",
      price: 9439,
      actualPrice: 7999,
      duration: "/ year",
      inventoryCount: "50K",
      billCount: "50K",
      sms: "50K",
    },
    {
      subscriptionId: 3,
      name: "Enterprise",
      price: 17699,
      actualPrice: 14999,
      duration: "/ year",
      inventoryCount: "2L",
      billCount: "2L",
      sms: "2L",
    },
  ];

  const FEATURE = [
    "Inventory Management",
    "Analytics",
    "Payment Reports and Payment Status",
    "Re-Order and Demand Prediction",
    "Customizable Template",
    "Bill Template and Recent Product List",
    "Cloud Web & Mobile App (Android & iOS)",
    "99.9% Uptime SLA",
    "Historical Analytics",
    "Advanced Permissions",
    "Unlimited Storage for Product Images",
  ];

  return (
    <div className="content-dashboard-inventory subscription-container">
      <div className="title-route-container menu-title-div">
        <h4 className="menu-title-h">Subscription & Billing Usage</h4>
      </div>

      <Card className="subscription-cards-container">
        <CardBody>
          {loadingFilter ? (
            <ChildLoader />
          ) : (
            subData && (
              <div className="">
                <div className="company-details-card active-plan-row mt-2">
                  <h5 className="usage-card-title">Plan Details</h5>
                  <Row>
                    <Col xs="6" md="3">
                      <p className="usage-label">Company ID:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">{subData.companyId}</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-label">Company Name:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">{subData.companyName}</p>
                    </Col>

                    <Col xs="6" md="3">
                      <p className="usage-label">Subscription ID:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">{subData.subscriptionId}</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-label">Subscription Name:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">{subData.subscriptionName}</p>
                    </Col>

                    <Col xs="6" md="3">
                      <p className="usage-label">Start Date:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">
                        {`${new Date(subData.startDate)
                          .getDate()
                          .toString()
                          .padStart(2, "0")}/${(
                          new Date(subData.startDate).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}/${new Date(
                          subData.startDate
                        ).getFullYear()}`}
                      </p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-label">End Date:</p>
                    </Col>
                    <Col xs="6" md="3">
                      <p className="usage-value">{`${new Date(subData.endDate)
                        .getDate()
                        .toString()
                        .padStart(2, "0")}/${(
                        new Date(subData.endDate).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}/${new Date(
                        subData.endDate
                      ).getFullYear()}`}</p>
                    </Col>
                  </Row>
                </div>

                <div className="company-details-card mt-4">
                  <h5 className="usage-card-title">Billing Usage</h5>
                  <Row>
                    <Col xs="6" md="2">
                      <p className="usage-label">
                        Invoice Usage:&nbsp;
                        <span className="usage-value">
                          {subData.invoiceUsage}
                        </span>
                      </p>
                    </Col>
                    <Col xs="6" md="2">
                      <p className="usage-label">
                        Inventory Usage:&nbsp;
                        <span className="usage-value">
                          {subData.inventoryUsage}
                        </span>
                      </p>
                    </Col>

                    <Col xs="6" md="2">
                      <p className="usage-label">
                        SMS Usage:&nbsp;
                        <span className="usage-value">{subData.smsUsage}</span>
                      </p>
                    </Col>

                    <Col xs="6" md="2">
                      <p className="usage-label">
                        Email Usage:&nbsp;
                        <span className="usage-value">
                          {subData.emailUsage}
                        </span>
                      </p>
                    </Col>

                    <Col xs="6" md="2">
                      <p className="usage-label">
                        WhatsApp Usage:&nbsp;
                        <span className="usage-value">
                          {subData.whatsUpUsage}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            )
          )}

          {/* Subscription Cards */}
          <Row className="card-container">
            {PLANS.map((plan, index) => (
              <Col key={index} xs={12} md={4} lg={4}>
                <div
                  className={`subscription-plan-card ${
                    plan.subscriptionId ===
                      currentSubscriptionDetail.subscriptionId &&
                    "current-active-plan-card"
                  }`}
                >
                  <p className="plan-name">{plan.name}</p>
                  <div className="plan-price">
                    <p>
                      <span>₹{plan.price}</span>&nbsp;
                      {plan.duration}
                    </p>
                  </div>
                  <div className="actual-price">
                    <p>
                      Plan Price: ₹{plan.actualPrice} GST (18%): ₹{" "}
                      {plan.actualPrice > 0
                        ? Math.ceil(plan.actualPrice * 0.18)
                        : 0}
                    </p>
                  </div>
                  <div className="plan-features">
                    <p>
                      <span>{plan.inventoryCount}</span> Inventory Count
                    </p>
                    <p>
                      <span>{plan.billCount}</span> Maximum Invoice / Bill Count
                    </p>
                    <p>
                      <span>{plan.sms}</span> SMS, Email, WhatsApp Count
                    </p>
                  </div>

                  {plan.subscriptionId !== 1 && (
                    <div className="plan-selection-btn">
                      {plan.subscriptionId ===
                      currentSubscriptionDetail.subscriptionId ? (
                        <RazorPayPayment
                          companyName={currentSubscriptionDetail?.companyName}
                          title="Renew Plan"
                          amount={
                            currentSubscriptionDetail?.paidAmount_Subscrption
                          }
                          manageSuccessPayment={() =>
                            manageSuccessPayment(true)
                          }
                        />
                      ) : (
                        <button
                          onClick={() =>
                            manageUpgrade({
                              subscriptionId: plan.subscriptionId,
                              subscriptionName: plan.name,
                              amount:
                                plan.actualPrice +
                                Math.round(plan.actualPrice * 0.18),
                            })
                          }
                        >
                          Upgrade Plan
                        </button>
                      )}
                    </div>
                  )}

                  {plan.subscriptionId ===
                    currentSubscriptionDetail.subscriptionId && (
                    <div className="active-plan-badge">Current Plan</div>
                  )}
                </div>
              </Col>
            ))}
          </Row>

          {/* Other Features */}
          <div className="other-features-wrapper">
            <div className="other-features-container">
              <div className="other-features-title">
                <h4>Additional Features Included in All Plans</h4>
              </div>
              <ul className="feature-list">
                {FEATURE.map((item, index) => (
                  <li className="feature-item" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={showModal}
        toggle={toggle}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="upgrade-plan-modal"
      >
        <ModalBody>
          <div className="header">
            <h3>Upgrade Plan</h3>
            <IoCloseCircleOutline
              size={25}
              onClick={toggle}
              className="cursor-pointer"
              cursor="pointer"
            />
          </div>

          <div className="upgrade-plan-details">
            <p>
              Current Subscription:&nbsp;
              <span>{currentSubscriptionDetail?.subscriptionName}</span>
            </p>
            <p>
              New Subscription:&nbsp;
              <span>{selectedSubscriptionDetail?.subscriptionName}</span>
            </p>
            <p>
              Final Amount:&nbsp;
              <span>₹ {selectedSubscriptionDetail?.amount}</span>
            </p>
            <div className="terms-and-conditions-checkbox-container">
              <input
                disabled={
                  isCustomPlanPayment
                    ? customPlanAmount.errorMessage.length > 0
                      ? true
                      : false
                    : false
                }
                type="checkbox"
                onChange={(e) => setTCChecked(e.target.checked)}
              />

              <label>
                I agree to the{" "}
                <a href="/auth/terms" target="_blank">
                  Terms and Conditions
                </a>
              </label>
            </div>

            <div className="upgrade-plan-btn">
              <RazorPayPayment
                companyName={currentSubscriptionDetail?.companyName}
                title="Pay Now"
                disabled={TCChecked ? false : true}
                amount={selectedSubscriptionDetail?.amount}
                isCustomPlanPayment={false}
                manageSuccessPayment={() => manageSuccessPayment()}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Subscription;
