import PageLoader from "components/Loader/PageLoader";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import percentage from "assets/img/icons/createBill/percentage3.png";
import rupee from "assets/img/icons/createBill/rupee.png";
import { useReactToPrint } from "react-to-print";
import Invoice from "./Invoice";
import { BsCurrencyRupee } from "react-icons/bs";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";
import { ADMIN_URL_BILL_DASHBOARD } from "helpers/Paths";
import "assets/styles/CreateBill.css";
import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import moment from "moment";
import { RiArrowRightLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

const RefundBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currency = new CreateBillRepository().getCurrencyAbbriviation();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{}, { getBill }, { refundBill }] = useCreateBillStore();

  const [initialQuantities, setInitialQuantities] = useState({});
  const [loader, setLoader] = useState(false);
  const [qty, setQty] = useState();
  const [rate, setRate] = useState();
  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState();
  const [productCostBeforeTax, setProductCostBeforeTax] = useState(0);
  const [SGST, setSGST] = useState();
  const [CGST, setCGST] = useState();
  const [productCost, setProductCost] = useState(0);
  const [rows, setRows] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(null);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [showInRupees, setShowInRupees] = useState(false);
  const [billId, setBillId] = useState(0);
  const [igstPercentage, setIgstPercentage] = useState(0);
  const [branchDetail, setBranchDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [initialGrandTotal, setInitialGrandTotal] = useState(0);
  const [billData, setBillData] = useState(null);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [expiryDays, setExpiryDays] = useState(null);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerData, setCustomerData] = useState({
    customerID: 0,
    customerName: "",
    address: "",
    phoneNumber: "",
    customerEmail: "",
  });
  const componentRef = useRef();

  const fetchBillData = async (billId) => {
    const fetchBillData = await getBill(billId);
    setBillData(fetchBillData);
    setSelectedCustomer(fetchBillData.customerName);
    setCustomerData({
      customerID: fetchBillData.customerId,
      customerName: fetchBillData.customerName,
      address: fetchBillData.customerAddress,
      phoneNumber: fetchBillData.customerContact,
      customerEmail: fetchBillData.customerEmail,
    });
    setRows(fetchBillData.productInfos);
    setManualDiscount(fetchBillData.manualDiscount);
    setTotalAmount(fetchBillData.grandTotal);
    setTotalBeforeTax(fetchBillData.totalBeforeTax);
    setIgstPercentage(fetchBillData.gst);
    setBillId(billId);

    const newInitialQuantities = fetchBillData.productInfos.reduce(
      (acc, row, index) => {
        acc[index] = row.qty - row.refundQty;
        return acc;
      },
      {}
    );

    setInitialQuantities(newInitialQuantities);

    const isPercentage =
      fetchBillData.manualDiscountType === "percentage" ? true : false;
    setShowInRupees(!isPercentage);
    setPaymentDone(fetchBillData.paidStatus);
    setPaymentMethod(fetchBillData.paymentMode);
    setSubTotal(fetchBillData.subTotal);
    setLoader(false);
    setInitialGrandTotal(parseFloat(fetchBillData.grandTotal));
  };

  useEffect(() => {
    // Fetch expiry_days from localStorage
    const companyProfile = JSON.parse(localStorage.getItem("companyProfile"));

    if (companyProfile) {
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      if (expiryDaysObj) {
        setExpiryDays(Number(expiryDaysObj.feature_value)); // Ensure it's a number
      }
    }
  }, []);

  const updateRow = (index, field, value) => {
    setFirstTimeLoad(false);
    const initialQty = initialQuantities[index]; // Make sure initialQuantities is correctly set up
    let newValue = value;
    // If updating quantity, check against initial quantity
    if (field === "qty") {
      newValue = !value ? "" : Math.min(Math.max(0, value), initialQty); // Ensure newValue is between 0 and initialQty

      if (value > initialQty) {
        setAlerts({
          type: "warning",
          message: ` You can not refund more than ${initialQty} quantity`,
        });
      }
    }

    const updatedRows = rows.map((row, idx) => {
      if (idx === index) {
        const updatedRow = { ...row, [field]: newValue };

        // Perform calculations only if this is the row being updated
        updatedRow.amount = updatedRow.qty * updatedRow.rate;
        updatedRow.productCost = updatedRow.amount - updatedRow.discountAmount;
        updatedRow.netAmount =
          updatedRow.productCost +
          (updatedRow.productCost * updatedRow.sgst) / 100 +
          (updatedRow.productCost * updatedRow.cgst) / 100;

        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);
  };

  useEffect(() => {
    setLoader(true);
    fetchBillData(id);
  }, []);

  useEffect(() => {
    const newAmount = qty * rate;

    if (isNaN(newAmount)) {
      // Check for NaN
      setAmount(0);
      return;
    }

    setAmount(newAmount);

    if (isNaN(discount)) {
      setProductCostBeforeTax(newAmount);
      const totalCostWithTax = Math.max(
        newAmount + (newAmount * SGST) / 100 + (newAmount * CGST) / 100,
        0.0
      );
      setProductCost(parseFloat(totalCostWithTax));
      return;
    }
    const totalCostWithTax = Math.max(
      newAmount -
        discount +
        ((newAmount - discount) * SGST) / 100 +
        ((newAmount - discount) * CGST) / 100,
      0.0
    );

    const newProductCostBeforeTax = Math.max(newAmount - discount, 0.0);
    setProductCostBeforeTax(newProductCostBeforeTax);

    setProductCost(Math.max(totalCostWithTax, 0.0));
  }, [qty, rate, discount]);

  useEffect(() => {
    const total = parseFloat(
      rows?.reduce((acc, row) => acc + parseFloat(row.productCost), 0)
    );
    setSubTotal(Math.max(total, 0.0));

    const manualDiscountAmountCalculation = showInRupees
      ? manualDiscount !== null
        ? parseFloat(manualDiscount)
        : 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0; // Apply manual discount

    setManualDiscountAmount(manualDiscountAmountCalculation);

    if (igstPercentage >= 0 && igstPercentage <= 100) {
      const igst = (totalWithDiscount * igstPercentage) / 100; // Calculate IGST
      setIgstAmount(igst); // Set IGST amount

      setTotalBeforeTax(totalWithDiscount);
      setTotalAmount(totalWithDiscount + igst);
    }
  }, [rows, updateRow, manualDiscount, showInRupees, igstPercentage]);

  const handleRefundBill = async () => {
    setLoading(true);
    try {
      const productInfoData = rows.map((item) => {
        let sellPriceCal;
        let price;
        let basePriceField;
        let disc;
        if (item?.sellPriceDiscountFixed) {
          disc = item?.sellPriceApplicableDiscountAmount;
        } else {
          disc =
            (item?.maximumRetailPrice * item?.sellPriceDiscountPercentage) /
            100;
        }
        price = Number(item?.maximumRetailPrice - disc);

        let basePrice = Number(item?.maximumRetailPrice - disc);
        let basePriceForTax =
          Number(basePrice) /
          (1 +
            (Number(item?.cgst) + Number(item?.sgst) + Number(item?.gstRate)) /
              100);

        // Convert the number to a string and trim it to exactly 6 decimal places without rounding
        let basePriceForTaxString = basePriceForTax.toString();
        let [integerPart, decimalPart] = basePriceForTaxString.split(".");

        decimalPart = decimalPart ? decimalPart.substring(0, 6) : "000000"; // Keep only the first 6 decimal places
        let finalBasePriceForTax = `${integerPart}.${decimalPart}`;

        let cgstAmount =
          Number(finalBasePriceForTax) * (Number(item?.cgst) / 100);
        let sgstAmount =
          Number(finalBasePriceForTax) * (Number(item?.sgst) / 100);
        let igstAmount =
          Number(finalBasePriceForTax) * (Number(item?.gstRate) / 100);

        let totaltaxAmount = cgstAmount + sgstAmount + igstAmount;

        basePriceField = price - totaltaxAmount;
        sellPriceCal = (
          item?.maximumRetailPrice -
          disc -
          totaltaxAmount
        ).toFixed(2);

        return {
          productId: item.productId,
          productName: item.productName,
          qty: item.qty,
          rate: basePriceField.toFixed(2),
          cgst: item.cgst,
          cgstType: "PERCENTAGE",
          sgst: item.sgst,
          expiryDate: item?.expiryDate || "",
          sgstType: "PERCENTAGE",
          discountAmount: disc?.toString(),
          productDiscountType:
            item?.sellPriceDiscountFixed > 0
              ? "FIXED"
              : item.sellPriceDiscountPercentage > 0.0
              ? "PERCENTAGE"
              : "",
          productDiscountValue:
            item?.sellPriceDiscountFixed > 0
              ? item?.sellPriceApplicableDiscountAmount
              : item?.sellPriceDiscountPercentage > 0.0
              ? item?.sellPriceDiscountPercentage
              : 0,
          amount: item.amount,
          expiryDate: item?.expiryDate || null,
          productCost: item.productCost,
          netAmount: item.productCost.toFixed(2),
        };
      });

      const params = {
        companyId: Common.getCompanyId(),
        userId: Common.getUserId(),
        companyName: Common.getCompanyName(),
        companyAddress: Common.getCompanyAddress(),
        companyPhone: Common.getCompanyPhone(),
        companyEmail: Common.getCompanyEmail(),
        companyGSTN: Common.getGstIn(),
        footerText: "Disclaimer and Return Policy",
        invoiceFileName: billId + ".pdf",
        invoiceDate: Common.getFormatedDate(
          moment().utc(),
          "DD-MMMM-YYYY hh:mm:ss",
          true
        ),
        editableStatus: 1,
        refundPossible: 1,
        billDescription: "invoice",
        invoiceReferenceNumber: "",
        billId: billId,
        refundBillId: parseInt(id),
        customerId: customerData.customerID,
        customerName: customerData.customerName,
        customerAddress: customerData.address,
        customerContact: customerData.phoneNumber,
        customerEmail: customerData.customerEmail,
        productInfos: productInfoData,
        subTotal: subTotal?.toFixed(2),
        manualDiscount: manualDiscount ? manualDiscount : "0.00",
        manualDiscountAmount: manualDiscountAmount
          ? manualDiscountAmount?.toFixed(2)
          : "0.00",
        manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
        totalBeforeTax: totalBeforeTax?.toFixed(2),
        totalTax: igstAmount?.toFixed(2),
        gst: igstPercentage?.toFixed(2),
        gstType: "PERCENTAGE",
        paidStatus: true,
        paymentMode: paymentMethod,
        grandTotal: totalBeforeTax?.toFixed(2),
      };

      const result = await refundBill(params);

      // Check if the API returned errorNumber 50000
      if (result.errorNumber != 0) {
        setLoading(false);
        await setAlerts({
          type: "danger",
          message:
            result.errorMessage ||
            "You cannot refund more quantity than the bill's maximum quantity.",
        });
        return; // Return early if the specific error condition is met
      }

      // Handle success case
      setBillId(result.billId);

      if (result.billId === 0) {
        setLoading(false);
        await setAlerts({ type: "danger", message: "Error Bill Id is 0." });
      } else {
        setLoading(false);
        await setAlerts({
          type: "success",
          message: `Refund Bill Id ${result.billId} generated for the Invoice Bill Id: ${result.refundBillId}`,
        });
        navigate(ADMIN_URL_BILL_DASHBOARD);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await setAlerts({
        type: "danger",
        message: "Error saving bill. Please try again later.",
      });
    }
  };

  const formatDate = (dateString) => {
    if (dateString !== null) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="create-bill-container">
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <h4 className="title m-0">Bills / Recent Bills / Refund Bill</h4>
          </div>

          <Card>
            <div
              className="mb-3 pb-4"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="title-row">
                    <h4>Customer Name</h4>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center" style={{ rowGap: "10px" }}>
                <Col xs="12" sm="12" md="6" lg="3">
                  {customerData.customerName}
                </Col>
              </Row>
            </div>

            <>
              <div className="title-row">
                <h4>Purchase Details</h4>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table className="purchase-table">
                  <thead>
                    <tr>
                      <th className="product-name-col">Particulars</th>
                      <th className="qty-col">Quantity</th>
                      <th className="expiry-col pr-2">Expiry Date</th>
                      <th className="mrp-col">MRP</th>
                      <th className="discount-col">Discount ({currency})</th>
                      <th className="basePrice-col">Base Price</th>
                      {/* <th className="total-price-col">Total</th> */}
                      <th className="cgst-col">CGST (%)</th>
                      <th className="sgst-col">SGST (%)</th>
                      <th className="igst-col">IGST (%)</th>
                      <th className="rate-col">Price</th>
                      <th className="net-amount-col">Product Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => {
                      // Declare variables here before the return statement (JSX)
                      let sellPriceCal;
                      let price;
                      let basePriceField;
                      let disc;
                      if (row?.sellPriceDiscountFixed) {
                        disc = row?.sellPriceApplicableDiscountAmount;
                      } else {
                        disc =
                          (row?.maximumRetailPrice *
                            row?.sellPriceDiscountPercentage) /
                          100;
                      }
                      price = Number(row?.maximumRetailPrice - disc);
                      let basePrice = Number(row?.maximumRetailPrice) - disc;
                      let basePriceForTax =
                        Number(basePrice) /
                        (1 +
                          (Number(row?.cgst) +
                            Number(row?.sgst) +
                            Number(row?.gstRate)) /
                            100);

                      // Convert the number to a string and trim it to exactly 6 decimal places without rounding
                      let basePriceForTaxString = basePriceForTax.toString();
                      let [integerPart, decimalPart] =
                        basePriceForTaxString.split(".");

                      decimalPart = decimalPart
                        ? decimalPart.substring(0, 6)
                        : "000000"; // Keep only the first 6 decimal places
                      let finalBasePriceForTax = `${integerPart}.${decimalPart}`;

                      let cgstAmount =
                        Number(finalBasePriceForTax) *
                        (Number(row?.cgst) / 100);
                      let sgstAmount =
                        Number(finalBasePriceForTax) *
                        (Number(row?.sgst) / 100);
                      let igstAmount =
                        Number(finalBasePriceForTax) *
                        (Number(row?.gstRate) / 100);

                      let totaltaxAmount = cgstAmount + sgstAmount + igstAmount;

                      basePriceField = price - totaltaxAmount;
                      sellPriceCal = (
                        row?.maximumRetailPrice -
                        disc -
                        totaltaxAmount
                      ).toFixed(2);

                      return (
                        <tr key={index}>
                          <td className="product-name-col cutomize-form-control">
                            {row.productName}
                          </td>

                          <td className="qty-col cutomize-form-control">
                            <Input
                              className="cutomize-form-control-input custom-number-input"
                              id={`qty-input-${index}`}
                              type="text"
                              value={
                                firstTimeLoad
                                  ? row.qty - row.refundQty
                                  : row.qty
                              }
                              onChange={(e) => {
                                const newQty = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "qty",
                                  isNaN(newQty) ? "" : newQty
                                );
                              }}
                            />
                          </td>

                          <td
                            className="expiry-col cutomize-form-control p-0"
                            style={{
                              fontSize: "15px",
                              color:
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                                  ? "red" // Optional visual indicator for disabled
                                  : "green",
                            }}
                          >
                            {Common.getFormatedDate(
                              row?.expiryDate,
                              "DD MMM YYYY",
                              true
                            ) !== "Invalid date"
                              ? Common.getFormatedDate(
                                  row?.expiryDate,
                                  "DD MMM YYYY",
                                  true
                                )
                              : "N/A"}
                          </td>

                          <td className="amount-col cutomize-form-control paddingLRZero">
                            {row?.maximumRetailPrice?.toFixed(2)}
                          </td>

                          <td className="discount-col cutomize-form-control paddingLRZero">
                            <div
                              className="custom-manual-discount-control"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                              >
                              <Input
                                type="number"
                                className="manual-discount-input"
                                min="0"
                                maxLength={10}
                                readOnly
                                disabled
                                value={
                                  isNaN(row?.sellPriceApplicableDiscountAmount)
                                    ? ""
                                    : row?.sellPriceDiscountFixed > 0
                                    ? row?.sellPriceApplicableDiscountAmount
                                    : row?.sellPriceDiscountPercentage
                                }
                                placeholder="Enter Discount"
                                style={{
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white", // Grey background for disabled, white otherwise
                                  width: "60px",
                                  height: "35px",
                                }}
                                // onBlur={() => calculateSellPriceDiscount()}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              />
                              <Input
                                disabled
                                id="discount"
                                name="sellPriceDiscountFixed"
                                type="select"
                                className="type-dropdown"
                                style={{
                                  width: "100px",
                                  height: "35px",
                                  padding: "0px",
                                }}
                                value={
                                  row?.sellPriceDiscountFixed ? "true" : "false"
                                }
                                onChange={(e) => {
                                  const sellPriceDiscountFixed = e.target.value;
                                  updateRow(
                                    index,
                                    "sellPriceDiscountFixed",
                                    sellPriceDiscountFixed
                                  );
                                }}
                                // onBlur={() => calculateSellPriceDiscount()}
                              >
                                <option value={true}>Fixed</option>
                                <option value={false}>Percentage</option>
                              </Input>
                              <div
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  data-tooltip-id="totalTooltip"
                                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                     <p style="margin: 0; padding: 0;">
  Discount: (${currency}) ${disc?.toFixed(2)}
</p>
                     </span>`}
                                >
                                  <RiArrowRightLine
                                    style={{
                                      marginLeft: "8px",
                                      color: "#4a6cf7",
                                      fontSize: "20px", // Increase this value to make the icon larger
                                    }}
                                  />
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#434ce6",
                                      color: "white",
                                    }}
                                    id="totalTooltip"
                                    place="bottom"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="basePrice-col cutomize-form-control paddingLRZero">
                            {basePriceField?.toFixed(2)}
                          </td>

                          <td className="cgst-col cutomize-form-control paddingLRZero">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                disabled
                                style={{
                                  cursor: "not-allowed",
                                  height: "34.5px",
                                  fontSize: "14px",
                                  paddingRight: "8px",
                                  width: "60%",
                                  boxSizing: "border-box",
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white",
                                }}
                                className="cutomize-form-control-input custom-number-input"
                                type="number"
                                onKeyDown={(e) =>
                                  handleKeyDown(e, autoCompleteRef)
                                }
                                value={row?.cgst || 0}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  updateRow(
                                    index,
                                    "cgst",
                                    Math.max(0, isNaN(value) ? 0 : value)
                                  );
                                }}
                                readOnly
                              />
                              <div
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  data-tooltip-id="totalTooltip"
                                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                     <p style="margin: 0; padding: 0;">
  CGST: (${currency}) ${cgstAmount?.toFixed(2)}
</p>
                     </span>`}
                                >
                                  <RiArrowRightLine
                                    style={{
                                      marginLeft: "8px",
                                      color: "#4a6cf7",
                                      fontSize: "20px", // Increase this value to make the icon larger
                                    }}
                                  />
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#434ce6",
                                      color: "white",
                                    }}
                                    id="totalTooltip"
                                    place="bottom"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className="sgst-col cutomize-form-controlpaddingLRZero">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                disabled
                                style={{
                                  cursor: "not-allowed",
                                  height: "34.5px",
                                  fontSize: "14px",
                                  paddingRight: "8px",
                                  width: "60%",
                                  boxSizing: "border-box",
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white",
                                }}
                                className="cutomize-form-control-input custom-number-input"
                                type="number"
                                readOnly
                                value={row?.sgst || 0}
                                // onKeyDown={(e) => handleKeyDown(e, sgstRef)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  updateRow(
                                    index,
                                    "sgst",
                                    Math.max(0, isNaN(value) ? 0 : value)
                                  );
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <span
                                  data-tooltip-id="totalTooltip"
                                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                                                              <p style="margin: 0; padding: 0;">
  SGST: (${currency}) ${sgstAmount?.toFixed(2)}
</p>
                                                            </span>`}
                                >
                                  <RiArrowRightLine
                                    style={{
                                      marginLeft: "8px",
                                      color: "#4a6cf7",
                                      fontSize: "20px", // Increase this value to make the icon larger
                                    }}
                                  />
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#434ce6",
                                      color: "white",
                                    }}
                                    id="totalTooltip"
                                    place="bottom"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className="igst-col cutomize-form-controlpaddingLRZero">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                disabled
                                style={{
                                  cursor: "not-allowed",
                                  height: "34.5px",
                                  fontSize: "14px",
                                  paddingRight: "8px",
                                  width: "60%",
                                  boxSizing: "border-box",
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white",
                                }}
                                className="cutomize-form-control-input custom-number-input"
                                type="number"
                                readOnly
                                value={row?.gstRate || 0}
                                // onKeyDown={(e) => handleKeyDown(e, sgstRef)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  updateRow(
                                    index,
                                    "gstRate",
                                    Math.max(0, isNaN(value) ? 0 : value)
                                  );
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <span
                                  data-tooltip-id="totalTooltip"
                                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                                                             <p style="margin: 0; padding: 0;">
                                IGST: (${currency}) ${igstAmount?.toFixed(2)}
</p>
                                                            </span>`}
                                >
                                  <RiArrowRightLine
                                    style={{
                                      marginLeft: "8px",
                                      color: "#4a6cf7",
                                      fontSize: "20px", // Increase this value to make the icon larger
                                    }}
                                  />
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#434ce6",
                                      color: "white",
                                    }}
                                    id="totalTooltip"
                                    place="bottom"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className="rate-col cutomize-form-control paddingLRZero">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Input
                                disabled
                                className="custom-number-input"
                                type="number"
                                readOnly
                                value={price?.toFixed(2)}
                                onChange={(e) => {
                                  const newRate = parseFloat(e.target.value);
                                  updateRow(
                                    index,
                                    "rate",
                                    Math.max(0, isNaN(newRate) ? 0 : newRate)
                                  );
                                }}
                                style={{
                                  cursor: "not-allowed",
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white", // Grey background for disabled, white otherwise
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  data-tooltip-id="totalTooltip"
                                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">

                                 <p style="margin: 0; padding: 0;">
                                Sell Price: (${currency}) ${sellPriceCal}
</p>
                                                             <p style="margin: 0; padding: 0;">
                                Buy Price: (${currency}) ${(() => {
                                    return row?.buyPrice?.toFixed(2);
                                  })()}
</p>
 <p style="margin: 0; padding: 0;">
                                Profit: (${currency}) ${(
                                    sellPriceCal - row?.buyPrice
                                  ).toFixed(2)}
</p>
                                                            </span>`}
                                >
                                  <RiArrowRightLine
                                    style={{
                                      marginLeft: "8px",
                                      color: "#4a6cf7",
                                      fontSize: "20px", // Increase this value to make the icon larger
                                    }}
                                  />
                                  <Tooltip
                                    style={{
                                      backgroundColor: "#434ce6",
                                      color: "white",
                                    }}
                                    id="totalTooltip"
                                    place="bottom"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className="net-amount-col cutomize-form-control paddingLRZero">
                            <span
                              data-tooltip-id="net-amount-tooltip"
                              data-tooltip-html={(() => {
                                // Calculate rate
                                let rate;
                                if (row?.sellPriceDiscountFixed) {
                                  rate =
                                    row?.maximumRetailPrice -
                                    row?.sellPriceApplicableDiscountAmount;
                                } else {
                                  rate =
                                    row?.maximumRetailPrice -
                                    (row?.maximumRetailPrice *
                                      row?.sellPriceDiscountPercentage) /
                                      100;
                                }
                                rate =
                                  rate >= 0 ? parseFloat(rate.toFixed(2)) : 0; // Ensure `rate` is numeric

                                // Generate tooltip HTML
                                return `<span style="color: white; margin: 0; padding: 0; display: block;">
                            <p style="margin: 0; padding: 0;">CGST: (${currency}) ${cgstAmount?.toFixed(
                                  2
                                )}</p>
                            <p style="margin: 0; padding: 0;">SGST: (${currency}) ${sgstAmount?.toFixed(
                                  2
                                )}</p>
                            <p style="margin: 0; padding: 0;">IGST: (${currency}) ${igstAmount?.toFixed(
                                  2
                                )}</p>
                        </span>`;
                              })()}
                            >
                              {(() => {
                                // Calculate rate and total
                                let rate;
                                if (row?.sellPriceDiscountFixed) {
                                  rate =
                                    row?.maximumRetailPrice -
                                    row?.sellPriceApplicableDiscountAmount;
                                } else {
                                  rate =
                                    row?.maximumRetailPrice -
                                    (row?.maximumRetailPrice *
                                      row?.sellPriceDiscountPercentage) /
                                      100;
                                }
                                rate =
                                  rate >= 0 ? parseFloat(rate.toFixed(2)) : 0; // Ensure `rate` is numeric
                                const total = row?.qty * rate; // Calculate total

                                // Store the calculated total in row?.productCost
                                row.productCost = total >= 0 ? total : 0; // Ensure it's not negative

                                return total?.toFixed(2); // Return total formatted to 2 decimal places
                              })()}
                            </span>
                            <Tooltip
                              style={{
                                backgroundColor: "#434ce6",
                                color: "white",
                              }}
                              id="net-amount-tooltip"
                              place="bottom"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>

            <div className="invoice-summary-container">
              <Row className="w-100">
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="title">Refund Bill</h4>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ rowGap: "10px" }}>
                    <Col sx={12} sm={12} md={4} lg={3}>
                      <div className="invoice-form-control">
                        <label>Payment Mode</label>
                        <Input
                          className="custom-input-control"
                          id="payment"
                          name="payment"
                          type="text"
                          readOnly
                          value={paymentMethod}
                        />
                      </div>
                    </Col>
                    <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label htmlFor="">Payment Details</label>
                        <Input
                          id="comment"
                          name="comment"
                          placeholder="Write down.."
                          type="textarea"
                          maxLength={200}
                          rows={2}
                          className="invoice-comment-control"
                        />
                      </div>
                    </Col>
                    <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label>
                          Manual Discount ({showInRupees ? "₹" : "%"})
                        </label>
                        <div className="custom-manual-discount-control">
                          <input
                            disabled
                            type="number"
                            min="0"
                            max="100"
                            readOnly
                            className="manual-discount-input"
                            value={manualDiscount}
                            style={{ width: "100px", cursor: "not-allowed" }}
                            onChange={(e) => {
                              let value = parseFloat(e.target.value);
                              if (isNaN(value)) {
                                value = "";
                              }

                              if (!showInRupees) {
                                if (value > 100) {
                                  value = 100;
                                } else if (value < 0) {
                                  value = 0;
                                }
                              }
                              setManualDiscount(value);
                            }}
                          />
                          <Input
                            id="discount"
                            name="discount"
                            type="select"
                            value={showInRupees}
                            className="type-dropdown"
                            readOnly
                          >
                            <option value={true}>Fixed</option>
                            <option value={false}>Percentage</option>
                          </Input>
                        </div>
                      </div>
                    </Col>
                    {/* <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label htmlFor="">IGST (%)</label>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            disabled
                            className="manual-igst-minus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage - 1)
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            readOnly
                            className="manual-igst-input"
                            value={igstPercentage || 0}
                            style={{ width: "55px" }}
                            onChange={(e) => {
                              setIgstPercentage(e.target.value);
                            }}
                          />
                          <button
                            disabled
                            className="manual-igst-plus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage + 1)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  {/* <Row className="my-3" style={{ rowGap: "10px" }}>
                    <Col sm={12} md={6}>
                      <div className="invoice-form-control">
                        <label htmlFor="">Comments</label>
                        <Input
                          id="comment"
                          name="comment"
                          placeholder="Write down.."
                          type="textarea"
                          maxLength={200}
                          rows={1}
                          className="invoice-comment-control"
                        />
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      className="d-flex align-items-end justify-content-start pb-2"
                      style={{ gap: "10px" }}
                    >
                      <input
                        type="checkbox"
                        id="custom-checkbox"
                        className="custom-checkbox"
                        checked={paymentDone}
                        onChange={() => {
                          setPaymentDone((prev) => !prev);
                        }}
                        disabled
                      />
                      <p className="received-payment-label">Received Payment</p>
                    </Col>
                  </Row> */}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  className="d-flex flex-column justify-content-between"
                >
                  <Row style={{ gap: "15px" }}>
                    {/* <Col md={12} className="invoice-summary-data-row">
                      <p>Total</p>
                      <p style={{ color: "#483EB6" }}>
                        {'(' + currency + ') ' + getSymbolFromCurrency(currency) + ' ' + totalBeforeTax}
                      </p>
                    </Col> */}
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Sub Total</p>
                      <p style={{ color: "#483EB6" }}>{subTotal?.toFixed(2)}</p>
                    </Col>
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Manual Discount</p>
                      <p style={{ color: "#FF0000" }}>
                        {"- " + (manualDiscountAmount ?? 0)?.toFixed(2)}
                      </p>
                    </Col>
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Net Payable Amount</p>
                      <p style={{ color: "#58AD6B", fontSize: "15px" }}>
                        {" "}
                        {"(" +
                          (currency ?? "0") +
                          ") " +
                          (getSymbolFromCurrency(currency) ?? "") +
                          " " +
                          (totalBeforeTax ?? 0)?.toFixed(2)}{" "}
                      </p>
                    </Col>
                  </Row>
                  {billData?.refundPossible === 1 && (
                    <>
                      <Row className="mt-2">
                        <Col
                          md={12}
                          className="d-flex justify-content-start align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <CustomButton
                            label={"Refund Bill"}
                            disabled={loading || billData?.refundBillId}
                            onClick={handleRefundBill}
                          />

                          {/* <CustomButton
                            label={loadingPrint ? "Saving..." : "Refund & Print"}
                            disabled={loadingPrint || billData?.refundBillId}
                            onClick={handlePrint}
                          /> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div style={{ overflow: "hidden", height: 0 }}>
                            <Invoice
                              ref={componentRef}
                              rows={rows}
                              subTotal={subTotal}
                              totalBeforeTax={parseFloat(
                                totalBeforeTax
                              )?.toFixed(2)}
                              igst={igstAmount?.toFixed(2)}
                              total={totalAmount?.toFixed(2)}
                              manualDiscount={manualDiscount}
                              manualDiscountAmount={manualDiscountAmount}
                              customerData={customerData}
                              selectedCustomer={selectedCustomer}
                              branchDetail={branchDetail}
                              billID={billId}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col sm={12} md={12}>
                      {billData?.refundPossible === 0 && (
                        <center>
                          <div style={{ color: "red", fontSize: "20px" }}>
                            You are not allowed to perform this operation.
                          </div>
                        </center>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default RefundBill;
