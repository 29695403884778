import CustomButton from "components/Button/CustomButton";
import PageLoader from "components/Loader/PageLoader";
import Common from "helpers/Common";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    Row,
} from "reactstrap";
import "../../assets/styles/CreateBill.css";
import { useCompanyStore } from "hooks/Company";

const AddCustomerModal = ({
    isModalOpen,
    setIsModalOpen,
    customerID = null,
}) => {
    const [formData, setFormData] = useState({
        customerName: "",
        activeStatus: 1,
        emailId: "",
        phoneNumber: "",
        description: "",
        pincode: "",
        address1: "",
        address2: "",
        street_Area: "",
        city: "",
        state: "",
        country: "India",
    });
    const [formDataValidate, setFormDataValidate] = useState({
        customerName: "",
        emailId: "",
        phoneNumber: "",
    });
    const [loader, setLoader] = useState(false);
    const [countries, setCountries] = useState([]);

    const [{}, {}, { createCustomer, updateCustomer }] = useCreateBillStore();
    const [{}, { getCustomerById }, {}] = useCompanyStore();
    const [{}, {}, { setAlerts }] = useLoginStore();

    const getCountryList = () => {
        let countryData = localStorage.getItem("countryList");
        if (!countryData) {
            fetch("https://restcountries.com/v3.1/all")
                .then((response) => response.json())
                .then((data) => {
                    const countryNames = data.map(
                        (country) => country.name.common
                    );
                    countryNames.sort(); // Sort the countries alphabetically

                    localStorage.setItem(
                        "countryList",
                        JSON.stringify(countryNames)
                    );

                    setCountries(countryNames);
                })
                .catch((error) =>
                    console.error("Error fetching countries:", error)
                );
        } else {
            setCountries(JSON.parse(countryData));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clone the validation object to avoid direct state mutation
        let error = { ...formDataValidate };

        // Perform validations based on the input field's name
        if (
            (name === "customerName" && !Common.verifyLength(value, 1)) ||
            (name === "emailId" &&
                (!Common.verifyLength(value, 5) ||
                    !Common.verifyEmail(value))) || // Use your existing verification method
            (name === "phoneNumber" && !Common.verifyLength(value, 10))
        ) {
            error[name] = "has-danger"; // Set error state if validation fails
        } else {
            error[name] = "has-success"; // Set success state if validation passes
        }
        setFormDataValidate(error); // Update validation state
    };

    const handleMobileChange = (e) => {
        setFormData({
            ...formData,
            phoneNumber: "+" + e,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let error = false;
        let validate = { ...formDataValidate };

        if (!formData.customerName) {
            error = true;
            validate.customerName = "has-danger";
        }
        setFormDataValidate(validate);

        try {
            if (!error) {
                setLoader(true);
                const response = customerID
                    ? await updateCustomer({
                          ...formData,
                          customerID: customerID,
                      })
                    : await createCustomer(formData);
                if (response?.data == false) {
                    setLoader(false);
                    await setAlerts({
                        type: "danger",
                        message: response?.message,
                    });
                }
                if (response?.data == true) {
                    setLoader(false);
                    await setAlerts({
                        type: "success",
                        message: `Customer ${
                            customerID ? "Updated" : "Created"
                        } Successfully`,
                    });
                }

                setIsModalOpen(!isModalOpen);
            }
        } catch (error) {
            console.error(
                `Error ${customerID ? "Updating" : "Creating"} Customer`,
                error
            );
            setLoader(false);
            await setAlerts({
                type: "danger",
                message: `Error ${
                    customerID ? "Updating" : "Creating"
                } Customer`,
            });
        }
    };

    const toggle = () => {
        setIsModalOpen(!isModalOpen);
        setFormDataValidate({});
        // getCountryList();
        setFormData({
            customerName: "",
            activeStatus: 1,
            emailId: "",
            phoneNumber: "",
            description: "",
            pincode: "",
            address1: "",
            address2: "",
            street_Area: "",
            city: "",
            state: "",
            country: "India",
        });
    };

    const preventPincodeInput = (e) => {
        let value = e.target.value.trim(); // Trim any leading or trailing spaces
        const isNegative = value.startsWith("-");

        // Remove non-digit characters
        value = value?.replace(/\D/g, "");

        // Limit to 10 digits
        value = value?.slice(0, 5);

        // If it's negative, append the negative sign
        if (isNegative) {
            value = "-" + value;
        }

        e.target.value = value;
    };

    const mobileInputValidClass = "form-control";

    const getCutomerDataByID = async (id) => {
        setLoader(true);
        const data = await getCustomerById(id);
        setFormData({
            customerName: data?.customerName,
            emailId: data?.emailId,
            phoneNumber: data?.phoneNumber,
            country: data?.country,
            state: data?.state,
            pincode: data?.pincode,
            address1: data?.address1,
            address2: data?.address2,
            street_Area: data?.street_Area,
            city: data?.city,
            description: data?.description,
            activeStatus: data?.activeStatus,
            customerID: data?.customerID,
        });
        // setSelectedCustomer(data?.customerName);
        // setisCustomerSelected(true);
        setLoader(false);
    };

    // To get customer data from id
    useEffect(() => {
        if (customerID) {
            getCutomerDataByID(customerID);
            setFormData({
                ...formData,
            });
        }
    }, [customerID]);

    useEffect(() => {
        getCountryList();
        const handleKeyDown = (event) => {
            // Check if 'Alter' key and 'A' key are pressed together
            if (event.altKey && event.key.toLowerCase() === "a") {
                const isChatBotActive =
                    localStorage.getItem("isChatBotActive") === "true" || false;
                if (!isChatBotActive) {
                    event.preventDefault(); // Prevent the default 'Select All' action
                    setIsModalOpen(true); // Set modal state to true
                }
            }
        };

        // Add event listener to handle keydown events
        window.addEventListener("keydown", handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Modal
            isOpen={isModalOpen}
            toggle={toggle}
            fullscreen={"xl"}
            size={"xl"}
            centered
        >
            <ModalBody>
                <Container>
                    {loader ? (
                        <PageLoader />
                    ) : (
                        <Form
                            onSubmit={handleSubmit}
                            className="new-customer-form"
                        >
                            <Row className="header-row">
                                <Col
                                    xs="12"
                                    md="12"
                                    lg="12"
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <p className="m-0">Create New Customer</p>
                                    <IoClose
                                        size={20}
                                        onClick={toggle}
                                        className="cursor-pointer"
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="customerName"
                                            className="label-addcustomer"
                                        >
                                            Customer Name
                                        </Label>
                                        <span className="text-danger ml-1">
                                            *
                                        </span>
                                        <Input
                                            type="text"
                                            name="customerName"
                                            id="customerName"
                                            value={formData.customerName}
                                            placeholder="Enter Customer Name"
                                            onChange={handleChange}
                                            valid={
                                                formDataValidate?.customerName ===
                                                "has-success"
                                            }
                                            invalid={
                                                formDataValidate?.customerName ===
                                                "has-danger"
                                            }
                                            maxLength={100}
                                        />
                                        <FormFeedback>
                                            Customer name is required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="phoneNumber"
                                            className="label-addcustomer"
                                        >
                                            Phone Number{" "}
                                        </Label>
                                        {/* <span className="text-danger ml-1">
                                            *
                                        </span> */}
                                        <Input
                                            type="text"
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            value={formData.phoneNumber}
                                            placeholder="Enter Phone Number"
                                            disabled
                                            hidden
                                        />
                                        <PhoneInput
                                            containerClass="phone-input-custom"
                                            buttonClass="flag-search-btn-custom"
                                            dropdownClass="flag-dropdown-custom"
                                            country={"in"}
                                            inputClass={mobileInputValidClass}
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            enableSearch={true}
                                            placeholder="+91 00000-00000"
                                            prefix="+"
                                            value={formData.phoneNumber}
                                            onChange={(e) => {
                                                handleMobileChange(e);
                                                if (e !== null || e !== "") {
                                                    setFormDataValidate({
                                                        ...formDataValidate,
                                                        phoneNumber:
                                                            "has-success",
                                                    })
                                                }
                                            }}
                                            enableLongNumbers={true} // Allows longer phone numbers
                                        />
                                        <FormFeedback>
                                            Please enter valid number.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="emailId"
                                            className="label-addcustomer"
                                        >
                                            Email ID
                                        </Label>
                                        <Input
                                            type="email"
                                            name="emailId"
                                            id="emailId"
                                            placeholder="Enter Email ID"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.emailId}
                                            className={formDataValidate.emailId}
                                        />
                                        {formDataValidate.emailId ===
                                            "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Please enter a valid email
                                                address.
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>

                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="address1"
                                            className="label-addcustomer"
                                        >
                                            Address 1
                                        </Label>
                                        <Input
                                            type="text"
                                            name="address1"
                                            id="address1"
                                            placeholder="Enter Address 1"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.address1}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="address2"
                                            className="label-addcustomer"
                                        >
                                            Address 2
                                        </Label>
                                        <Input
                                            type="text"
                                            name="address2"
                                            id="address2"
                                            placeholder="Enter Address 2"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.address2}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="street_Area"
                                            className="label-addcustomer"
                                        >
                                            Street/Area
                                        </Label>
                                        <Input
                                            type="text"
                                            name="street_Area"
                                            id="street_Area"
                                            placeholder="Enter Street/Area"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.street_Area}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="pincode"
                                            className="label-addcustomer"
                                        >
                                            Pincode
                                        </Label>
                                        <Input
                                            name="pincode"
                                            id="pincode"
                                            placeholder="Enter Pincode"
                                            onChange={handleChange}
                                            type="number"
                                            min="0"
                                            onKeyDown={preventPincodeInput}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            value={formData.pincode}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="city"
                                            className="label-addcustomer"
                                        >
                                            City
                                        </Label>
                                        <Input
                                            type="text"
                                            name="city"
                                            id="city"
                                            placeholder="Enter City"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.city}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="state"
                                            className="label-addcustomer"
                                        >
                                            State
                                        </Label>
                                        <Input
                                            type="text"
                                            name="state"
                                            id="state"
                                            placeholder="Enter State"
                                            onChange={handleChange}
                                            maxLength={100}
                                            value={formData.state}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="country"
                                            className="label-addcustomer"
                                        >
                                            Country
                                        </Label>
                                        <Input
                                            type="select"
                                            name="country"
                                            id="country"
                                            onChange={handleChange}
                                            value={formData?.country}
                                        >
                                            <option value="" disabled selected>
                                                Select your country
                                            </option>
                                            {countries.map((country, index) => (
                                                <option
                                                    key={index}
                                                    value={country}
                                                >
                                                    {country}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" lg="4">
                                    <FormGroup>
                                        <Label
                                            for="description"
                                            className="label-addcustomer"
                                        >
                                            Description
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            id="description"
                                            placeholder="Enter Description"
                                            onChange={handleChange}
                                            maxLength={100}
                                            className="description-text-area"
                                            value={formData.description}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    xs="12"
                                    md="6"
                                    lg="4"
                                    className="d-flex align-items-end justify-content-between"
                                >
                                    <CustomButton
                                        label="Submit"
                                        type="submit"
                                        customStyle="add-customer-submit-btn"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Container>
            </ModalBody>
        </Modal>
    );
};

export default AddCustomerModal;
