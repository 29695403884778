import BaseRepository from "./BaseRepository";
import InventoryAPICall from "../service/InventoryAPICall";

class InventoryRepository extends BaseRepository {
  createModifyInventory = async (params) => {
    return await new InventoryAPICall().createModifyInventory(params);
  };

  inventoryReport = async (params) => {
    return await new InventoryAPICall().inventoryReport(params);
  };

  uploadBlobs = async (params) => {
    return await new InventoryAPICall().uploadBlobs(params);
  };

  getFileContent = async (params) => {
    return await new InventoryAPICall().getFileContent(params);
  };

  uploadInventoryByFile = async (params) => {
    return await new InventoryAPICall().uploadInventoryByFile(params);
  };

  uploadedInventorySummary = async (params) => {
    return await new InventoryAPICall().uploadedInventorySummary(params);
  };

  uploadedInventorySummaryDetails = async (params) => {
    return await new InventoryAPICall().uploadedInventorySummaryDetails(params);
  };

  getTabsSet = async () => {
    return await new InventoryAPICall().getTabsSet();
  };
  
  getCategoryList = async () => {
    return await new InventoryAPICall().getCategoryList();
  };

  getSubCategoryList = async (params) => {
    return await new InventoryAPICall().getSubCategoryList(params);
  };

  getCategorySubCategorySearchList = async (params) => {
    return await new InventoryAPICall().getCategorySubCategorySearchList(
      params
    );
  };

  assignInventory = async (params) => {
    return await new InventoryAPICall().assignInventory(params);
  };

  createModifyDiscountByCategory = async (params) => {
    return await new InventoryAPICall().createModifyDiscountByCategory(params);
  };

  createModifyDiscountByProduct = async (params) => {
    return await new InventoryAPICall().createModifyDiscountByProduct(params);
  };

  getDiscountDetailsByProductId = async (params) => {
    return await new InventoryAPICall().getDiscountDetailsByProductId(params);
  };

  getDiscountDetailsByCategoryId = async (params) => {
    return await new InventoryAPICall().getDiscountDetailsByCategoryId(params);
  };

  reorderStockReport = async (params) => {
    return await new InventoryAPICall().reOrderStockReport(params);
  };

  getProductExpiryDateReport = async (params) => {
    return await new InventoryAPICall().getProductExpiryDateReport(params);
  };

  getProductDetailsById = async (id, params) => {
    return await new InventoryAPICall().getProductDetailsById(id, params);
  };

  AddNewCategory = async (params) => {
    return await new InventoryAPICall().AddNewCategory(params);
  };

  ModifyCategory = async (params) => {
    return await new InventoryAPICall().ModifyCategory(params);
  };

  AddNewSubCategory = async (params) => {
    return await new InventoryAPICall().AddNewSubCategory(params);
  };

  ModifySubCategory = async (params) => {
    return await new InventoryAPICall().ModifySubCategory(params);
  };
}

export default InventoryRepository;
