import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Input,
  Row,
  Tooltip,
  Collapse,
  Button,
  CardBody,
  Card,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import AddCustomerModal from "../AddCustomerModal";
import EditCustomerModal from "../EditCustomerModal";
import CustomerBillHistoryModal from "../CustomerBillHistoryModal";
import TemplateButton from "../TemplateButton";
import SuggestedProductsButton from "../SuggestedProductsButton";
import billHistory from "assets/img/icons/createBill/bill_history.png";
import draft from "assets/img/icons/createBill/draft.png";
import CustomButton from "components/Button/CustomButton";
import { MdOutlineEditNote } from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { FaUserPlus } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useAzureStore } from "hooks/Azure";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import { RiFileEditFill } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import { RiUserAddFill } from "react-icons/ri";
import { FaHistory } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import BillHistoryModal from "views/BillHistory/BillHistoryModal";
import PatientBillForm from "views/CreateBill/PatientBillForm";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ButtonGroup } from "reactstrap";

import { IoClose } from "react-icons/io5";
import { useLoginStore } from "hooks/Login";
import PageLoader from "components/Loader/PageLoader";

const pageSize = new CompanyProfileRepository().getPageSize();

const CustomerDetails = ({
  customerData,
  setCustomerData,
  selectedCustomer,
  setSelectedCustomer,
  handleTemplateSelect,
  suggestedProductList,
  handleSuggestedProductSelect,
  selectedCustomerID,
  setSelectedCustomerID,
  recentBillId,
  setSelectedRecentProduct,
  fetchBillDataById,
}) => {
  const [
    {},
    { getCustomerDetail, Get_Bills_By_CustomerID },
    { updateCustomer },
  ] = useCreateBillStore();
  const [billFormModal, setBillFormModal] = useState(false);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [recentPopoverOpen, setRecentPopoverOpen] = useState(false);
  const [suggestedPopoverOpen, setSuggestedPopoverOpen] = useState(false);
  const [customerBillHistoryData, setCustomerBillHistoryData] = useState([]);
  const [backdrop, setBackdrop] = useState(true);
  const [keyboard, setKeyboard] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);
  var localRecentBillid = localStorage.getItem("recentBillId");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const popupRef = useRef();
  const [{}, {}, { createCustomer }] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loader, setLoader] = useState(false);
  const [formDataValidate, setFormDataValidate] = useState({});
  const [isOn, setIsOn] = useState(() => {
    // Check if 'scan' is stored in localStorage and return true or false accordingly
    const savedState = localStorage.getItem("scan");
    return savedState === "ON"; // If 'scan' is 'ON', set state to true, otherwise false
  });
  
  // Initialize state from localStorage
  const [isOpenViewBill, setIsOpenViewBill] = useState(() => {
    const storedValue = localStorage.getItem("isOpenViewBill");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : recentBillId && recentBillId !== 0;
  });

  // Function to toggle view bill and store state in localStorage
  const toggleViewBill = () => {
    setIsOpenViewBill((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isOpenViewBill", JSON.stringify(newState));
      return newState;
    });
  };

  useEffect(() => {
    // Store recentBillId in localStorage whenever it changes
    if (recentBillId && recentBillId !== 0) {
      localStorage.setItem("recentBillId", recentBillId);
    }
  }, [recentBillId]);

  // Handle changes for emailId
  const handleEmailChange = (e) => {
    setCustomerData((prevData) => ({
      ...prevData,
      emailId: e.target.value,
    }));
  };

  // Handle changes for phoneNumber
  const handlePhoneChange = (e) => {
    setCustomerData((prevData) => ({
      ...prevData,
      phoneNumber: e.target.value,
    }));
  };

  const toggleRecentPopover = () => {
    setRecentPopoverOpen(!recentPopoverOpen);
  };

  const toggleSuggestedPopover = () => {
    setSuggestedPopoverOpen(!suggestedPopoverOpen);
  };
  const [{}, { fetchImagesFromAzure }, {}] = useAzureStore();
  const recentProductRef = useRef(null);
  const suggestedProductRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (recentBillId && recentBillId !== 0) {
      setIsOpenViewBill(true);
    }
  }, [recentBillId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        recentProductRef.current &&
        !recentProductRef.current.contains(event.target)
      ) {
        setRecentPopoverOpen(false);
      }
      if (
        suggestedProductRef.current &&
        !suggestedProductRef.current.contains(event.target)
      ) {
        setSuggestedPopoverOpen(false);
      }
    }
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLoadMore = () => {
    const data = customerBillHistoryData.slice(
      pageNumber * pageSize,
      pageNumber * pageSize + pageSize
    );
    setTableData((prev) => [...prev, ...data]);
    setPageNumber((prev) => prev + 1);
  };

  const autoCompleteRef = useRef(null);

  const retriveCustomers = async (queryText) => {
    try {
      let customerList = await getCustomerDetail(queryText);
      customerList = customerList.map((customer) => ({
        ...customer,
        customerFullName: customer.customerName,
      }));
      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          // Regular expression to match a phone number within parentheses
          const phoneRegex = /\(\+\d+\)/;
          const match = customer.customerName.match(phoneRegex);

          if (match) {
            const existingPhoneNumber = match[0];

            // If the existing phone number is different from the phoneNumber field
            if (existingPhoneNumber !== `(${customer.phoneNumber})`) {
              // Remove the old phone number and append the correct one
              customer.customerName = customer.customerName.replace(
                existingPhoneNumber,
                `(${customer.phoneNumber})`
              );
            }
          } else {
            // Only add the phone number if it's not empty or null
            if (customer.phoneNumber) {
              customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
            }
          }

          // If phoneNumber is empty, remove any remaining " ()" from the customerName
          if (!customer.phoneNumber) {
            customer.customerName = customer.customerName.replace(
              /\s?\(\)/,
              ""
            );
          }
        });
      };
      modifyCustomerNames();

      setFilteredCustomer(customerList); // Assume this is setting state, but unrelated to return value
      if (customerList.length === 0) {
        setNoResultFound(true);
      }

      return customerList; // Make sure to return the list here
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Ensure the error propagates properly
    }
  };

  const filterCustomer = (event) => {
    retriveCustomers(event.query);
  };

  useEffect(() => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipDraft, setTooltipDraft] = useState(false);

  const [tooltipOpenSuggestedProduct, setTooltipOpenSuggestedProduct] =
    useState(false);
  const [tooltipBillHistory, setTooltipBillHistory] = useState(false);
  const [tooltipRecentProducts, setTooltipRecentProducts] = useState(false);
  const [tooltipRecentBill, setTooltipRecentBill] = useState(false);
  const [tooltipModifyRecentBill, settooltipModifyRecentBill] = useState(false);

  const toggletooltipRecentProducts = () =>
    setTooltipRecentProducts(!tooltipRecentProducts);
  const toggletooltipRecentBill = () =>
    setTooltipRecentBill(!tooltipRecentBill);
  const toggletooltipModifyRecentBill = () =>
    settooltipModifyRecentBill(!tooltipModifyRecentBill);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleTooltipSuggested = () =>
    setTooltipOpenSuggestedProduct(!tooltipOpenSuggestedProduct);
  const toggleTooltipBillHistory = () =>
    setTooltipBillHistory(!tooltipBillHistory);
  const toggleTooltipDraft = () => setTooltipDraft(!tooltipDraft);

  const fetchPDF = async (name, type) => {
    let pdfURL = await fetchImagesFromAzure(name, "bill");
    if (type.toLowerCase() === "preview") {
      const response = await fetch(pdfURL);
      const pdfData = await response.blob();
      const file = new Blob([pdfData], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } else {
      window.open(pdfURL, "_blank");
    }
  };

  useEffect(() => {
    // Update localStorage whenever isOn changes
    localStorage.setItem("scan", isOn ? "ON" : "OFF");
  }, [isOn]);

  const handleToggle = () => {
    // Toggle the state
    setIsOn(!isOn);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
  };

  const fetchCustomerBillHistory = async (customerID) => {
    if (customerID) {
      setPageNumber(1);
      const customerBillHistory = await Get_Bills_By_CustomerID(customerID);

      setCustomerBillHistoryData(customerBillHistory);
      const data = customerBillHistory.slice(0, pageSize);
      setTableData(data);
      setBillFormModal(true);
      // toggle();
    }
  };

  const togglePopup = () => {
    // retrieveTemplatesList()
    setPopupVisible(!isPopupVisible);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup if clicked outside
      }
    };

    // Add event listener when the popup is visible
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when component unmounts or popup is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible, setPopupVisible]);

  const handleQuickAddCustomer = async (e) => {
    let params = {
      customerName: selectedCustomer,
      activeStatus: 1,
      emailId: "",
      phoneNumber: "",
      description: "",
      pincode: "",
      address1: "",
      address2: "",
      street_Area: "",
      city: "",
      state: "",
      country: "",
    };

    try {
      const response = await createCustomer(params);
      if (response?.data == false) {
        await setAlerts({
          type: "danger",
          message: response?.message,
        });
      }

      if (response?.data == true) {
        await setAlerts({
          type: "success",
          message: "Customer successfully created",
        });

        // Now call the new function after successful customer creation
        const customerData = await handleRetrieveAndModifyCustomers(
          selectedCustomer
        );
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      await setAlerts({
        type: "danger",
        message: "Error creating customer",
      });
    }
  };

  const handleRetrieveAndModifyCustomers = async (queryText) => {
    try {
      let customerList = await getCustomerDetail(queryText);

      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          // Modify customer name only if phoneNumber is not null, undefined, or an empty string
          if (customer.phoneNumber) {
            customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
          }
        });
      };

      modifyCustomerNames();

      setFilteredCustomer(customerList); // Set the filtered customer state
      if (customerList.length === 0) {
        setNoResultFound(true);
      }
      console.error("modifiedd customers:", customerList);
      setSelectedCustomer(customerList?.customerName);
      if (customerList?.customerID) {
        const customerID = customerList?.customerID;
        setSelectedCustomerID(customerID);
      } else {
        setSelectedCustomerID(null);
        setCustomerData({
          customerName: "",
          address1: "",
          address2: "",
          address: "",
          phoneNumber: "",
          emailId: "",
        });
      }
      const newAddress = `${customerList?.address1 ?? ""}, ${
        customerList?.address2 ?? ""
      }\n${customerList?.city ?? ""}, ${customerList?.state ?? ""}`;
      customerData.customerName = customerList?.customerName ?? "";
      customerData.address = newAddress;
      customerData.phoneNumber = customerList?.phoneNumber ?? "";
      customerData.emailId = customerList?.emailId ?? "";

      customerData.customerFullName = customerList?.customerFullName ?? "";
      customerData.customerID = customerList?.customerID ?? "";
      customerData.activeStatus = customerList?.activeStatus ?? "";
      customerData.description = customerList?.description ?? "";
      customerData.pincode = customerList?.pincode ?? "";
      customerData.address1 = customerList?.address1 ?? "";
      customerData.address2 = customerList?.address2 ?? "";
      customerData.street_Area = customerList?.street_Area ?? "";
      customerData.city = customerList?.city ?? "";
      customerData.state = customerList?.state ?? "";
      customerData.country = customerList?.country ?? "";
      // return customerList; // Return the modified customer list
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Ensure the error propagates properly
    }
  };

  // Handle submit (for manual form submission, called onblur as well)
  const handleMobileEmailChange = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false;
    let validate = { ...formDataValidate };

    if (!selectedCustomer) {
      error = true;
      validate.customerName = "has-danger";
    }

    setFormDataValidate(validate);

    try {
      if (!error) {
        let param = {
          customerID: customerData?.customerID,
          customerName: customerData?.customerName,
          activeStatus: customerData?.activeStatus,
          emailId: customerData?.emailId,
          phoneNumber: customerData?.phoneNumber,
          description: customerData?.description,
          pincode: customerData?.pincode,
          address1: customerData?.address1,
          address2: customerData?.address2,
          street_Area: customerData?.street_Area,
          city: customerData?.city,
          state: customerData?.state,
          country: customerData?.country,
        };
        const response = await updateCustomer(param);
        if (response?.data === false) {
          setLoader(false);
          await setAlerts({
            type: "danger",
            message: response?.message,
          });
        }
        if (response?.data === true) {
          setLoader(false);
          await setAlerts({
            type: "success",
            message: "Customer successfully updated",
          });
        }
      } else {
        setAlerts({ type: "danger", message: "Please select customer." });
      }
    } catch (error) {
      setLoader(false);
      console.error("Error updating customer:", error);
      await setAlerts({ type: "danger", message: "Error updating customer" });
    }
    setLoader(false);
  };

  // Handle onBlur for emailId and phoneNumber to trigger the API call
  const handleBlur = async () => {
    if (selectedCustomerID) {
      await handleMobileEmailChange({
        preventDefault: () => {}, // Override the form submission behavior since we don't want to submit the form
      });
    }
  };

  return (
    <div className="pb-3" style={{ borderBottom: "1px solid #E0E0E0" }}>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <Row className="align-items-center d-flex" style={{ rowGap: "7px" }}>
            <Col xs="12" sm="12" md="6" lg="3">
              <AutoComplete
                ref={autoCompleteRef}
                value={selectedCustomer}
                suggestions={filteredCustomer}
                completeMethod={filterCustomer}
                field="customerName"
                placeholder="Customer Name"
                showEmptyMessage={true}
                emptyMessage={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>No results found</span>
                    <CustomButton
                      label="Add customer"
                      id="recentProduct"
                      customStyle="custom-primary-button"
                      onClick={() => handleQuickAddCustomer()}
                    />
                    {/* <AddCustomerModal
                                    customerName={
                                        noResultFound ? selectedCustomer : ""
                                    }
                                    setNoResultFound={() =>
                                        setNoResultFound(false)
                                    }
                                    label="Add Customer"
                                /> */}
                  </div>
                }
                minLength={1}
                onChange={(e) => {
                  setSelectedCustomer(e.value);
                  if (e.value?.customerID) {
                    const customerID = e.value?.customerID;
                    setSelectedCustomerID(customerID);
                  } else {
                    setSelectedCustomerID(null);
                    setCustomerData({
                      customerName: "",
                      address1: "",
                      address2: "",
                      address: "",
                      phoneNumber: "",
                      emailId: "",
                    });
                  }
                  const newAddress = `${e.value?.address1 ?? ""}, ${
                    e.value?.address2 ?? ""
                  }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
                  customerData.customerName = e.value?.customerName ?? "";
                  customerData.address = newAddress;
                  customerData.phoneNumber = e.value?.phoneNumber ?? "";
                  customerData.emailId = e.value?.emailId ?? "";
                  customerData.customerFullName =
                    e.value?.customerFullName ?? "";
                  customerData.customerID = e.value?.customerID ?? "";
                  customerData.activeStatus = e.value?.activeStatus ?? "";
                  customerData.description = e.value?.description ?? "";
                  customerData.pincode = e.value?.pincode ?? "";
                  customerData.address1 = e.value?.address1 ?? "";
                  customerData.address2 = e.value?.address2 ?? "";
                  customerData.street_Area = e.value?.street_Area ?? "";
                  customerData.city = e.value?.city ?? "";
                  customerData.state = e.value?.state ?? "";
                  customerData.country = e.value?.country ?? "";
                }}
                pt={{
                  panel: {
                    className: "autocomplete-Zindex",
                  },
                  input: {
                    root: {
                      className: "custom-width-input form-control",
                    },
                  },
                }}
                className="custom-autocomplete"
                // style={{ width: "105%" }}
                delay={300}
              />
            </Col>

            <Col xs="12" sm="12" md="3" lg="2">
              <Input
                type="text"
                style={{
                  width: "107%", // Utilize full width of the column
                }}
                className="custom-input-control"
                placeholder="Mobile Number"
                delay={300}
                value={customerData?.phoneNumber || ""} // Use the value from customerData
                onChange={handlePhoneChange} // Handle change for phoneNumber
                readOnly={!selectedCustomerID} // Make the input readonly when no customer is selected
                maxLength={22}
                onBlur={handleBlur} // Trigger API call when email loses focus
              />
            </Col>

            <Col
              xs="12"
              sm="12"
              md="3"
              lg="2" // Changed from lg="2" to lg="3" to utilize more space
              style={{
                display: "flex",
              }}
            >
              <Input
                style={{
                  width: "100%", // Utilize full width of the column
                }}
                type="text"
                className="custom-input-control"
                placeholder="Email"
                delay={300}
                value={customerData?.emailId || ""} // Use the value from customerData
                onChange={handleEmailChange} // Handle change for emailId
                readOnly={!selectedCustomerID} // Make the input readonly when no customer is selected
                maxLength={30}
                onBlur={handleBlur} // Trigger API call when email loses focus
              />
            </Col>

            <Col xs="12" sm="8" md="4" lg="3">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
                className="filter-container-recent"
                ref={popupRef}
              >
                <button
                  disabled={!selectedCustomerID}
                  className="add-customer-btn"
                  id="recentBill"
                  type="button"
                  onClick={() => fetchCustomerBillHistory(selectedCustomerID)}
                  style={{
                    backgroundColor: selectedCustomerID ? "#434CE6" : "#e0e0e0",
                    color: selectedCustomerID ? "#fff" : "#808080",
                    cursor: selectedCustomerID ? "pointer" : "not-allowed",
                    border: selectedCustomerID
                      ? "1px solid #434CE6"
                      : "1px solid #e0e0e0",
                    padding: "0px",
                  }}
                >
                  <AiOutlineFileSearch size={18} />
                </button>
                <Tooltip
                  autohide={true}
                  flip={true}
                  placement="top"
                  isOpen={tooltipBillHistory}
                  target="recentBill"
                  toggle={toggleTooltipBillHistory}
                  style={{
                    backgroundColor: "#434CE6",
                    color: "#ffffff",
                  }}
                >
                  Bill History
                </Tooltip>

                <AddCustomerModal
                  customerName={noResultFound ? selectedCustomer : ""}
                  setNoResultFound={() => setNoResultFound(false)}
                />
                <EditCustomerModal />
                <button
                  style={{
                    textDecoration: "none",
                  }}
                  id="recentProducts"
                  className="add-customer-btn"
                  type="button"
                  onClick={() => {
                    // togglePopup();
                    setPopupVisible(true);
                  }}
                >
                  <MdOutlineProductionQuantityLimits size={16} />
                </button>
                {isPopupVisible && (
                  <>
                    <div
                      style={{
                        left: "-40px",
                      }}
                      className="filter-popup-recent"
                    >
                      <div
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          width: "100%",
                        }}
                        className="scrollbar-custom"
                      >
                        <div className="template-list-container">
                          {suggestedProductList &&
                          suggestedProductList.length > 0 ? (
                            suggestedProductList.map((item, index) => (
                              <div
                                key={index}
                                className="list-item-template-style"
                                onClick={() => {
                                  setSelectedRecentProduct({
                                    productId: item.productId,
                                    productName: item.productName,
                                    applicableDiscountAmount:
                                      item.applicableDiscountAmount,
                                    available_qty: item.available_qty,
                                    brand: item.brand,
                                    categoryId: item.categoryId,
                                    categoryName: item.categoryName,
                                    cgst: item.cgst,
                                    companyId: item.companyId,
                                    discountAmount: item.discountAmount,
                                    discountFixed: item.discountFixed,
                                    discountPercentage: item.discountPercentage,
                                    expiryDate: item.expiryDate,
                                    gstRate: item.gstRate,
                                    igstRate: item.igstRate,
                                    isTax1Percentage: item.isTax1Percentage,
                                    isTax2Percentage: item.isTax2Percentage,
                                    isazureSearchData: item.isazureSearchData,
                                    lead_time: item.lead_time,
                                    maximumRetailPrice: item.maximumRetailPrice,
                                    maximum_stock: item.maximum_stock,
                                    measure: item.measure,
                                    minimum_stock: item.minimum_stock,
                                    mrpDiscountAmount: item.mrpDiscountAmount,
                                    netPrice: item.netPrice,
                                    netProductCost: item.netProductCost,
                                    productCode: item.productCode,
                                    productDescription: item.productDescription,
                                    productImageName: item.productImageName,
                                    productItemcode: item.productItemcode,
                                    productShortcode: item.productShortcode,
                                    productType: item.productType,
                                    product_discount: item.product_discount,
                                    reorder_point: item.reorder_point,
                                    sgst: item.sgst,
                                    sizeDescription: item.sizeDescription,
                                    sku: item.sku,
                                    subCategoryId: item.subCategoryId,
                                    subCategoryName: item.subCategoryName,
                                    tax1: item.tax1,
                                    tax1Description: item.tax1Description,
                                    tax2: item.tax2,
                                    tax2Description: item.tax2Description,
                                    taxAmount: item.taxAmount,
                                    un_used_quantity: item.un_used_quantity,
                                    unit: item.unit,
                                    unitItems: item.unitItems,
                                    unitPrice: item.unitPrice,
                                    upc_code: item.upc_code,
                                  });
                                  // handleTemplateSelect(item.productId);
                                  // closePopup();
                                  setPopupVisible(false);
                                }}
                              >
                                {item.productName}
                              </div>
                            ))
                          ) : (
                            <div className="text-center">No Products Found</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <Tooltip
                  autohide={true}
                  flip={true}
                  placement="top"
                  isOpen={tooltipRecentProducts}
                  target="recentProducts"
                  toggle={toggletooltipRecentProducts}
                  style={{
                    backgroundColor: "#434CE6",
                    color: "#ffffff",
                  }}
                >
                  Recent Products
                </Tooltip>
                <a
                  href={
                    localRecentBillid
                      ? `/admin/editBill/${localRecentBillid}`
                      : "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <button
                    id="modfifyRecentBill"
                    className="add-customer-btn"
                    type="button"
                    onClick={handleButtonClick}
                    disabled={!localRecentBillid} // Disable if localRecentBillid is not available
                    style={{
                      backgroundColor: localRecentBillid
                        ? "#434CE6"
                        : "#e0e0e0",
                      color: localRecentBillid ? "#fff" : "#808080",
                      cursor: localRecentBillid ? "pointer" : "not-allowed",
                      border: localRecentBillid
                        ? "1px solid #434CE6"
                        : "1px solid #e0e0e0",
                      padding: "0px",
                    }}
                  >
                    <RiFileEditFill size={16} />
                  </button>
                </a>
                <Tooltip
                  autohide={true}
                  flip={true}
                  placement="top"
                  isOpen={tooltipModifyRecentBill}
                  target="modfifyRecentBill"
                  toggle={toggletooltipModifyRecentBill}
                  style={{
                    backgroundColor: "#434CE6",
                    color: "#ffffff",
                  }}
                >
                  Modify Recent Bill
                </Tooltip>
                <button
                  id="viewRecentBill"
                  style={{
                    backgroundColor: localRecentBillid ? "#434CE6" : "#e0e0e0",
                    color: localRecentBillid ? "#fff" : "#808080",
                    cursor: localRecentBillid ? "pointer" : "not-allowed",
                    border: localRecentBillid
                      ? "1px solid #434CE6"
                      : "1px solid #e0e0e0",
                    padding: "0px",
                  }}
                  onClick={(e) => {
                    if (localRecentBillid) {
                      // Only fetch PDF if localRecentBillid is available
                      fetchPDF(localRecentBillid, "preview");
                      handleButtonClick(e);
                    }
                  }}
                  className="add-customer-btn"
                  type="button"
                >
                  <GrView size={16} />
                </button>
                <Tooltip
                  autohide={true}
                  flip={true}
                  placement="top"
                  isOpen={tooltipRecentBill}
                  target="viewRecentBill"
                  toggle={toggletooltipRecentBill}
                  style={{
                    backgroundColor: "#434CE6",
                    color: "#ffffff",
                  }}
                >
                  View Recent Bill
                </Tooltip>
                <div style={{ display: "flex", width: "50%" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "115px",
                      height: "36px",
                      borderRadius: "25px",
                      backgroundColor: isOn ? "#434ce6" : "#bbb",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isOn ? "flex-end" : "flex-start",
                      padding: "5px",
                      cursor: "pointer",
                      transition: "0.3s",
                      boxShadow: isOn
                        ? "0 0 15px rgba(67, 76, 230, 0.5)"
                        : "0 0 5px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={handleToggle}
                  >
                    {/* Toggle Knob */}
                    <div
                      style={{
                        width: "36px",
                        height: "25px",
                        borderRadius: "25px",
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#434ce6",
                        fontSize: "12px",
                        fontWeight: "bold",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                        transition: "0.3s",
                      }}
                    ></div>

                    {/* Text Inside the Switch */}
                    <div
                      style={{
                        position: "absolute",
                        width: "122%",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15.2px",
                        transition: "0.3s",
                        opacity: isOn ? 1 : 0.5,
                        userSelect: "none",  // Prevents text selection
                      }}
                    >
                      {isOn ? "Scan On" : "Scan Off"}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col
              xs="12"
              sm="4"
              md="3"
              lg="2"
              className="d-flex justify-content-end align-items-center"
              style={{ gap: "10px" }}
            >
              <TemplateButton
                toggleRecentPopover={toggleRecentPopover}
                handleTemplateSelect={handleTemplateSelect}
              />
            </Col>
          </Row>
          <CustomerBillHistoryModal
            modal={billFormModal}
            toggle={() => setBillFormModal(false)}
            backdrop={true}
            keyboard={true}
            customerBillHistoryData={customerBillHistoryData}
            customerName={selectedCustomer?.customerName}
            handleLoadMore={handleLoadMore}
            tableData={tableData}
            fetchBillDataById={fetchBillDataById}
          />
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
