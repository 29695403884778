import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { isEmpty } from "underscore";
import { notificationMessages } from "helpers/constantMessages";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import PageLoader from "components/Loader/PageLoader";
import CustomButton from "components/Button/CustomButton";

const DiscountByCategory = () => {
  const [loader, setLoader] = useState(false);
  const [{}, {}, {}] = useInventoryStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const navigate = useNavigate();
  const [categorySelect, setCategorySelect] = useState(null);
  const [modifyCategorySelect, setModifyCategorySelect] = useState(null);
  const [subCategorySelect, setSubCategorySelect] = useState(null);
  const [modifySubCategorySelect, setModifySubCategorySelect] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [
    {},
    {
      getSubCategoryList,
      getCategoryList,
      getDiscountDetailsByCategoryId,
      AddNewCategory,
      ModifyCategory,
      AddNewSubCategory,
      ModifySubCategory,
    },
    { createModifyDiscountByCategory },
  ] = useInventoryStore();

  const [inventoryValidate, setInventoryValidate] = useState({});
  // const [isTax1Percentage, setIsTax1Percentage] = useState(true);
  // const [isTax2Percentage, setIsTax2Percentage] = useState(true);
  const [taxTypeSelect, setTaxTypeSelect] = useState(true);
  const [tax1, setTax1] = useState(0);
  const [tax2, setTax2] = useState(0);
  const [tax1Description, setTax1Description] = useState("");
  const [tax2Description, setTax2Description] = useState("");
  const [discountTypeSelect, setDiscountTypeSelect] = useState(null);
  const [discValue, setDiscValue] = useState(0);
  const [discount, setDiscount] = useState(0);
  // Modal State
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // 'add' or 'modify'

  const [isSubModalOpen, setSubModalOpen] = useState(false);
  const [subModalType, setSubModalType] = useState(""); // 'add' or 'modify'

  const [categoryName, setCategoryName] = useState("");
  const [modifiedCategoryName, setModifiedCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [modifiedSubCategoryName, setModifiedSubCategoryName] = useState("");

  const ref = useRef();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    let categoryList = await getCategoryList();
    let list =
      categoryList &&
      !isEmpty(categoryList).length > 0 &&
      categoryList.map((prop, key) => {
        return {
          value: prop.categoryID,
          label: prop.categoryName,
        };
      });
    setCategory(list);
  };

  const getSubCategory = async (val) => {
    let param = {
      categoryIds: val?.value.toString(),
    };
    let subCategoryList = await getSubCategoryList(param);
    let list =
      subCategoryList &&
      !isEmpty(subCategoryList).length > 0 &&
      subCategoryList.map((prop, key) => {
        return {
          value: prop.subCategoryID,
          label: prop.subCategoryName,
        };
      });
    setSubCategory(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    let validate = { ...inventoryValidate };

    // Validate category
    if (!categorySelect || !categorySelect.value) {
      error = true;
      setAlerts({
        type: "danger",
        message: "Category and Sub Category is required.",
      });
      //   validate.category = "has-danger";
      // } else {
      //   validate.category = "has-success";
    }

    // Validate subCategory
    if (!subCategorySelect || !subCategorySelect.value) {
      error = true;
      setAlerts({
        type: "danger",
        message: "Category and Sub Category is required.",
      });
      //   validate.subCategory = "has-danger";
      // } else {
      //   validate.subCategory = "has-success";
    }

    setInventoryValidate(validate);
    if (!error) {
      setLoader(true);
      let param = {
        categoryId: subCategorySelect.value,
        tax1: tax1,
        isTax1Percentage: taxTypeSelect,
        // tax1Description: tax1Description,
        // tax2: tax2,
        // isTax2Percentage: isTax2Percentage,
        // tax2Description: tax2Description,
        discountType: discountTypeSelect.label,
        dicountValue: discValue,
        discount: discount,
      };
      let res = await createModifyDiscountByCategory(param);
      setLoader(false);
      if (res) {
        await setAlerts({
          type: "success",
          message: notificationMessages.addInventoryDiscountSuccess,
        });
      }
    }
  };

  const close = () => {
    navigate(ADMIN_URL_DASHBOARD);
  };

  const handleCategorySelect = (val) => {
    setSubCategorySelect(null);
    getSubCategory(val);
  };

  const handleSubCategorySelect = async (val) => {
    setLoader(true);
    let param = {
      CategoryId: val?.value,
    };
    let discountDetails = await getDiscountDetailsByCategoryId(param);
    if (discountDetails && !isEmpty(discountDetails).length > 0) {
      // setIsTax1Percentage(discountDetails.isTax1Percentage);
      // setIsTax2Percentage(discountDetails.isTax2Percentage);
      setTaxTypeSelect(discountDetails.isTax1Percentage);
      setTax1(discountDetails.tax1);
      setTax2(discountDetails.tax2);
      setTax1Description(discountDetails.tax1Description);
      setTax2Description(discountDetails.tax2Description);
      setDiscountTypeSelect({
        value: discountDetails.discountType == "Fixed" ? 1 : 2,
        label: discountDetails.discountType,
      });
      setDiscValue(discountDetails.dicountValue);
      setDiscount(discountDetails.discount);
    }
    setLoader(false);
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  // Toggle Modal
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    setModalType("");
    setCategoryName("");
    setModifiedCategoryName("");
    setModifyCategorySelect(null);
  };

  const toggleSubModal = () => {
    setSubModalOpen(!isSubModalOpen);
    setSubModalType("");
    setSubCategoryName("");
    setModifiedSubCategoryName("");
    setModifySubCategorySelect(null);
  };

  // Handle Category Add/Modify Save
  const handleCategorySave = async () => {
    setLoader(true);
    try {
      if (modalType === "add") {
        // API call to add category
        let params = {
          productParentCategoryID: 0,
          productParentCategoryName: categoryName,
        };
        const response = await AddNewCategory(params);
        if (response) {
          getCategory();
          setAlerts({
            type: "success",
            message: "Category added successfully!",
          });
          setLoader(false);
        } else {
          setAlerts({
            type: "danger",
            message: "Failed to add category.",
          });
        }
      } else if (modalType === "modify") {
        // API call to modify category
        let params = {
          productParentCategoryID: modifyCategorySelect.value,
          productParentCategoryName: modifiedCategoryName,
        };
        const response = await ModifyCategory(params);
        if (response) {
          setLoader(false);
          getCategory();
          setAlerts({
            type: "success",
            message: "Category modified successfully!",
          });
        } else {
          setAlerts({
            type: "danger",
            message: "Failed to modify category.",
          });
        }
      }
      toggleModal();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error in category add/modify:", error);
      setAlerts({
        type: "danger",
        message: "An error occurred. Please try again.",
      });
    }
  };

  const handleSubCategorySave = async () => {
    setLoader(true);
    try {
      if (subModalType === "add") {
        // API call to add category
        let params = {
          categoryId: 0,
          categoryName: subCategoryName,
          categoryDescription: subCategoryName,
          productParentCategoryID: categorySelect.value,
        };
        const response = await AddNewSubCategory(params);

        if (response) {
          getSubCategory(categorySelect);
          setAlerts({
            type: "success",
            message: "Sub Category added successfully!",
          });
          setLoader(false);
        } else {
          setAlerts({
            type: "danger",
            message: "Failed to add sub category.",
          });
        }
      } else if (subModalType === "modify") {
        // API call to modify category
        let params = {
          categoryId: modifySubCategorySelect.value,
          categoryName: modifiedSubCategoryName,
          categoryDescription: modifiedSubCategoryName,
          productParentCategoryID: categorySelect.value,
        };
        const response = await ModifySubCategory(params);
        getSubCategory(categorySelect);
        if (response) {
          setLoader(false);
          getCategory();
          setAlerts({
            type: "success",
            message: "Sub Category modified successfully!",
          });
        } else {
          setAlerts({
            type: "danger",
            message: "Failed to modify Sub Category.",
          });
        }
      }
      toggleSubModal();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error in category add/modify:", error);
      setAlerts({
        type: "danger",
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
          <Row xs="3">
            <Col xs={12} md={6}>
              <FormGroup className="customer-input-container">
                <Label>Category *</Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <Input
                    className="w-100"
                    name="category"
                    placeholder="Select Category"
                    type="hidden"
                    valid={inventoryValidate?.category === "has-success"}
                    invalid={inventoryValidate?.category === "has-danger"}
                  />
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "290px", // Adjust width here
                      }),
                    }}
                    className="profile-custom-select payment-mode-select"
                    placeholder="Select Category"
                    name="category"
                    value={categorySelect}
                    options={category}
                    onChange={(e) => {
                      let error = { ...inventoryValidate };
                      if (e.value == null) {
                        error.category = "has-danger";
                      } else {
                        error.category = "has-success";
                      }
                      setInventoryValidate(error);
                      setCategorySelect(e);
                      handleCategorySelect(e);
                    }}
                  />
                  <CustomButton
                    style={{ minWidth: "120px" }}
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing
                      setModalType("add");
                      setModalOpen(true);
                    }}
                    label="Add Category"
                  />
                  <CustomButton
                    type="button"
                    style={{ minWidth: "120px" }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing
                      setModalType("modify");
                      setModalOpen(true);
                    }}
                    label="Modify Category"
                  />
                </div>
                <FormFeedback>Category is required.</FormFeedback>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup className="customer-input-container">
                <Label>Sub Category *</Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <Input
                    className="w-100"
                    name="subcategory"
                    placeholder="Select Sub Category"
                    type="hidden"
                    valid={inventoryValidate?.subCategory === "has-success"}
                    invalid={inventoryValidate?.subCategory === "has-danger"}
                  />
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "260px", // Adjust width here
                      }),
                    }}
                    className="profile-custom-select payment-mode-select"
                    placeholder="Select Sub Category"
                    name="subcategory"
                    value={subCategorySelect}
                    options={subCategory}
                    onChange={(e) => {
                      let error = { ...inventoryValidate };
                      if (e.value == null) {
                        error.subCategory = "has-danger";
                      } else {
                        error.subCategory = "has-success";
                      }
                      setInventoryValidate(error);
                      setSubCategorySelect(e);
                      handleSubCategorySelect(e);
                    }}
                  />
                  <CustomButton
                    style={{ minWidth: "120px" }}
                    type="button"
                    onClick={(e) => {
                      if (!categorySelect) {
                        setAlerts({
                          type: "danger",
                          message: "Please select Category.",
                        });
                        return;
                      }
                      e.stopPropagation(); // Prevent dropdown from closing
                      setSubModalType("add");
                      setSubModalOpen(true);
                    }}
                    label="Add Sub Category"
                  />
                  <CustomButton
                    style={{ minWidth: "120px" }}
                    type="button"
                    onClick={(e) => {
                      if (!categorySelect) {
                        setAlerts({
                          type: "danger",
                          message: "Please select Category.",
                        });
                        return;
                      }
                      e.stopPropagation(); // Prevent dropdown from closing
                      setSubModalType("modify");
                      setSubModalOpen(true);
                    }}
                    label="Modify Sub Category"
                  />
                </div>
                <FormFeedback>Sub Category is required.</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>Tax</label>
                <div className="inventory-discount-control">
                  <input
                    value={tax1}
                    placeholder="Enter Tax"
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => setTax1(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="taxType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setTaxTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>Discount</label>
                <div className="inventory-discount-control">
                  <input
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    value={discValue}
                    placeholder="Enter Discount Value"
                    onChange={(e) => setDiscValue(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="discType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setDiscountTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4">
              <FormGroup className="custom-input-container">
                <label>Total Discount</label>
                <Input
                  type="text"
                  // value={totalDiscount}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col xs="12" md="4">
              <CustomButton
                type="submit"
                label="Add Discount"
                style={{ backgroundColor: "#207841", margin: "20px 0" }}
              />
            </Col>
          </Row>
        </Form>
      )}

      {/* Modal for Add/Modify Category */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
          {modalType == "add" ? "Add New Category" : "Modify Category"}
        </ModalHeader>
        <ModalBody>
          {modalType == "add" ? (
            <FormGroup className="custom-input-container">
              Category Name *
              <Input
                maxLength={100}
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder={"Enter Category Name"}
              />
            </FormGroup>
          ) : (
            <>
              <FormGroup className="customer-input-container">
                <Label>Category *</Label>
                <Input
                  className="w-100"
                  placeholder="Select Category"
                  type="hidden"
                />
                <Select
                  className="profile-custom-select payment-mode-select"
                  placeholder="Select Category"
                  name="modifyCategory"
                  value={modifyCategorySelect}
                  options={category}
                  onChange={(e) => {
                    setModifyCategorySelect(e);
                  }}
                />
                <span>Select Category to modify</span>
              </FormGroup>

              <FormGroup className="custom-input-container">
                Category Name *
                <Input
                  disabled={!modifyCategorySelect}
                  maxLength={100}
                  type="text"
                  value={modifiedCategoryName}
                  onChange={(e) => setModifiedCategoryName(e.target.value)}
                  placeholder={"Enter Category Name"}
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter
          style={{
            paddingRight: "10px",
          }}
        >
          <Button
            color="primary"
            onClick={handleCategorySave}
            disabled={
              modalType == "add"
                ? !categoryName.trim()
                : !modifiedCategoryName.trim()
            }
            label="Add"
            style={{
              margin: "5px 0",
              width: "120px", // Button width can be adjusted
            }}
          >
            {modalType == "add" ? "Add" : "Modify"}
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Add/Modify sub Category */}
      <Modal isOpen={isSubModalOpen} toggle={toggleSubModal} centered={true}>
        <ModalHeader toggle={toggleSubModal}>
          {subModalType == "add"
            ? "Add New Sub Category"
            : "Modify Sub Category"}
        </ModalHeader>
        <ModalBody>
          {subModalType == "add" ? (
            <FormGroup className="custom-input-container">
              Sub Category Name *
              <Input
                maxLength={100}
                type="text"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                placeholder={"Enter Sub Category Name"}
              />
            </FormGroup>
          ) : (
            <>
              <FormGroup className="customer-input-container">
                <Label>Sub Category *</Label>
                <Input
                  className="w-100"
                  placeholder="Select Sub Category"
                  type="hidden"
                />
                <Select
                  className="profile-custom-select payment-mode-select"
                  placeholder="Select Sub Category"
                  value={modifySubCategorySelect}
                  options={subCategory}
                  onChange={(e) => {
                    setModifySubCategorySelect(e);
                  }}
                />
                <span>Select Sub Category to modify</span>
              </FormGroup>

              <FormGroup className="custom-input-container">
                Sub Category Name *
                <Input
                  disabled={!modifySubCategorySelect}
                  maxLength={100}
                  type="text"
                  value={modifiedSubCategoryName}
                  onChange={(e) => setModifiedSubCategoryName(e.target.value)}
                  placeholder={"Enter Sub Category Name"}
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter
          style={{
            paddingRight: "10px",
          }}
        >
          <Button
            color="primary"
            onClick={handleSubCategorySave}
            disabled={
              subModalType == "add"
                ? !subCategoryName.trim()
                : !modifiedSubCategoryName.trim()
            }
            label="Add"
            style={{
              margin: "5px 0",
              width: "120px", // Button width can be adjusted
            }}
          >
            {subModalType == "add" ? "Add" : "Modify"}
          </Button>{" "}
          <Button color="secondary" onClick={toggleSubModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DiscountByCategory;
